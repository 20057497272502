import { Icon } from "../components/Icons";
import { LinkToPage, PageID } from "../utils/PageLinking";

export enum DocumentReferenceType {
  Person = 'person',
  Place = 'place',
  Toponym = 'toponym',
  Project = 'project',
  MilityEntity = 'military_entity',
  Memorial = 'memorial',
  Document = 'document',
  DibornDocument = 'diborn-document',
  ContactPerson = 'contact-person',

  Empty = 'empty'
}

export enum DocumentReferenceRelation {
  Mention = 'mention',
  Author = 'author',
  AddressedTo = 'addressed_to',
  ServedIn = 'served_in',
  WrittenLocation = 'written_location',

  None = '' // not yet filled in
}

export interface IDocumentReference {
  _id?: string;
  type: DocumentReferenceType;
  name: string;
  relation: DocumentReferenceRelation;
  comment: string;
  info?: PersonInfo;
}

export interface IDocumentRefReference {
  ref_id?: string;
  ref_type: DocumentReferenceType;
  ref_name: string;
  ref_relation: DocumentReferenceRelation;
}

export interface PersonInfo {
  army?: string;
  serviceNumber?: string;
  diedDate?: { day: number, month: number, year: number }
}

export class DocumentReference {
  static fromJSON(json: IDocumentReference) {
    return new DocumentReference(
      json._id,
      json.type,
      json.name,
      json.relation,
      json.comment,
      json.info);
  }

  static fromJSONRef(json: IDocumentRefReference) {
    return new DocumentReference(
      json.ref_id,
      json.ref_type,
      json.ref_name,
      json.ref_relation,
      '');
  }

  toJSON(): IDocumentReference {
    return {
      _id: this.id,
      type: this.type,
      name: this.name,
      relation: this.relation,
      comment: this.comment,
    };
  }

  toJSONRef(): IDocumentRefReference {
    return {
      ref_id: this.id,
      ref_type: this.type,
      ref_name: this.name,
      ref_relation: this.relation
    }
  }

  static empty() {
    return new DocumentReference('', DocumentReferenceType.Empty, '', DocumentReferenceRelation.None, '');
  }

  static emptyWithName(name: string) {
    return new DocumentReference('', DocumentReferenceType.Empty, name, DocumentReferenceRelation.None, '');
  }

  id: string|undefined;
  type: DocumentReferenceType;
  name: string;
  relation: DocumentReferenceRelation;
  comment: string;
  info?: PersonInfo;

  constructor(
    id: string|undefined,
    type: DocumentReferenceType,
    name: string,
    relation?: DocumentReferenceRelation,
    comment?: string,
    info?: PersonInfo
  ) {
    this.id = id;
    this.type = type;
    this.name = name;
    this.relation = relation || DocumentReferenceRelation.None;
    this.comment = comment || '';
    this.info = info;
  }

  getIcon(): Icon {
    switch (this.type) {
      case DocumentReferenceType.Person: return Icon.User;
      case DocumentReferenceType.Place: return Icon.MapMarker;
      case DocumentReferenceType.MilityEntity: return Icon.Sitemap;
      case DocumentReferenceType.Project: return Icon.Book;
      case DocumentReferenceType.Memorial: return Icon.Monument;
      case DocumentReferenceType.ContactPerson: return Icon.User;
      default: return Icon.Question;
    }
  }

  getLink(): LinkToPage {
    switch (this.type) {
      case DocumentReferenceType.Person: return new LinkToPage(PageID.ViewPerson, this.id);
      case DocumentReferenceType.Place: return new LinkToPage(PageID.ViewPlace, this.id);
      case DocumentReferenceType.MilityEntity: return new LinkToPage(PageID.ViewMilitaryEntity, this.id);
      case DocumentReferenceType.Project: return new LinkToPage(PageID.ViewProject, this.id);
      case DocumentReferenceType.Memorial: return new LinkToPage(PageID.ViewMemorial, this.id);
      case DocumentReferenceType.ContactPerson: return new LinkToPage(PageID.EditContactPerson, this.id);
      default: return new LinkToPage(PageID.Home);
    }
  }

  toString() {
    if (this.type === DocumentReferenceType.Person) {
      const info = this.info;

      let result = this.name;
      if (info && info.army) {
        result += ' (' + info.army;
        if (info.serviceNumber)
          result += ' N°' + info.serviceNumber;
        result += ')';
      }
      if (info && info.diedDate)
        result += ' †' + info.diedDate.day + '/' + info.diedDate.month + '/' + info.diedDate.year;
      return result;
    } else {
      return this.name;
    }
  }

  isEmpty() {
    return this.type === DocumentReferenceType.Empty;
  }


}
