import React, { useEffect } from "react";
import api from "../../../api";
import { FormCard } from "../../../components/FormCard";
import { Icon } from "../../../components/Icons";
import { ContactPersonInput } from "../../../components/inputs/ContactPersonInput";
import FormGroup from "../../../components/inputs/FormGroup";
import { MapCoordinatesInput } from "../../../components/inputs/MapCoordinatesInput";
import { MapMarkerInput } from "../../../components/inputs/MapMarkerInput";
import { useContactPerson } from "../../../FunctionalAPI";
import { DocumentReference, DocumentReferenceType } from "../../../models/DocumentReference";
import { IPersonStory2 } from "../../../models/PersonStory2";
import { T } from "../../../Translate";
import { Loading } from "../../../utils/Loading";
import { ReferenceView } from "../../EditCollectionDocument/ReferenceView";
import { IKeyStoryErrors } from "../Stories/StoriesData";

interface KeyStoryTabProps {
  loading: Loading;
  story?: IPersonStory2;
  onChanged: (story: IPersonStory2) => void;
  errors: IKeyStoryErrors;
  readOnly: boolean;
}

export default function KeyStoryTab(props: KeyStoryTabProps) {
  const { loading, story, onChanged, errors, readOnly } = props;
  const position = (story && story.key_position && {
    lat: story.key_position.lat,
    lng: story.key_position.lon
  }) || undefined;

  const contactPerson = useContactPerson(loading, story?.linked_contact_person || undefined);

  return <>
    <FormCard icon={Icon.Key} title={T('page.personstory.tab.keystory')}>
      {story && <>
        <MapMarkerInput
          label={T('page.personstory.tab.keystory.position')}
          position={position}
          defaultCenter={position || {lat: 50.8512, lng: 2.8857}}
          readOnly={readOnly}
          onChange={position => {
            onChanged({
              ...story,
              key_position: {
                lat: position.lat,
                lon: position.lng
              }
            })
          }}
        />
        <MapCoordinatesInput
          label={T('page.personstory.tab.keystory.position')}
          name='keystory-coordinates'
          position={position || undefined}
          disabled={readOnly}
          onChange={position => onChanged({
            ...story,
            key_position: position ? {
              lat: position.lat,
              lon: position.lng
            } : null
          })}
          error={errors.keyPosition}
        />
      </>}
    </FormCard>
    <FormCard icon={Icon.Man} title={T('page.personstory.tab.keystory.contactperson')}>
      {story && !story.linked_contact_person && !readOnly && (
        <ContactPersonInput
          label={T('page.personstory.tab.keystory.contactperson')}
          value={undefined}
          onSelected={person => {
            onChanged({ ...story, linked_contact_person: person._id });
          }}
          error={errors.linkedContactPerson}
        />
      )}
      {story && contactPerson && (
        <FormGroup label={T('page.personstory.tab.keystory.contactperson')}>
          <div style={{ marginTop: 12 }}>
            <ReferenceView
              item={new DocumentReference(contactPerson._id, DocumentReferenceType.ContactPerson, contactPerson.firstName + ' ' + contactPerson.lastName)}
              readOnly={readOnly}
              onClickedRemove={() => onChanged({ ...story, linked_contact_person: null })}
            />
          </div>
        </FormGroup>
      )}
    </FormCard>
  </>;
}
