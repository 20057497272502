import { Route, Switch } from 'react-router-dom';

import { PageLayout } from './pages/Page';
import { History } from 'history';

import { ActivePageState, AppState } from './redux/State';
import { useSelector } from 'react-redux';
import PublicSearch from './pages/PublicSearch';
import { BaseApp, Renderers } from './BaseApp';
import { ViewPersonPublicPage } from './pages/ViewPersonPublic';

class App extends BaseApp {
  renderRouteSwitch(renderers: Renderers) {
    const { renderPage, renderWithProps } = renderers;

    return (
      <Switch>
        <Route
          exact
          path="/"
          render={renderPage(PublicSearch, PageLayout.Public)}
        />
        <Route
          exact
          path="/search"
          render={renderPage(PublicSearch, PageLayout.Public)}
        />
        <Route
          exact
          path="/search/:params"
          render={renderPage(PublicSearch, PageLayout.Public)}
        />
        <Route
          exact
          path="/person/:params"
          render={renderWithProps(
            ViewPersonPublicPage,
            (props, _, params) => ({ id: params._id }),
            PageLayout.PublicPerson
          )}
        />
        <Route
          exact
          path="/:params"
          render={renderPage(PublicSearch, PageLayout.Public)}
        />
      </Switch>
    );
  }
}

export default (props: { history: History }) => {
  const { history } = props;
  const page = useSelector<AppState, ActivePageState>(state => state.activePageState);
  return <App history={history} page={page} />;
}
