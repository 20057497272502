import moment from 'moment';
import NL from './translations/nl.json';
import EN from './translations/en.json';
import FR from './translations/fr.json';
import DE from './translations/de.json';

const languages = {
  'nl': NL,
  'en': EN as Partial<typeof NL>,
  'fr': FR as Partial<typeof NL>,
  'de': DE as Partial<typeof NL>
};
export type Language = keyof typeof languages;
export type TranslationKey = keyof typeof NL;

export const getLanguageStringForLanguage = (languageCode: string): string => {
  if (['nl', 'fr', 'de', 'en'].indexOf(languageCode) > -1) {
    return T(('language.' + languageCode) as TranslationKey)
  } else {
    return '???'
  }
}

interface INoun {
  singular: TranslationKey;
  plural: TranslationKey;
}
function noun(singular: TranslationKey, plural: TranslationKey): INoun {
  return { singular, plural };
}

const nouns = {
  collection: noun('noun.collection', 'noun.collections'),
  document: noun('noun.document', 'noun.documents'),
  documentTag: noun('noun.documentTag', 'noun.documentTags'),
  person: noun('noun.person', 'noun.persons'),
  militaryEntity: noun('noun.militaryEntity', 'noun.militaryEntities'),
  militaryUnit: noun('noun.militaryUnit', 'noun.militaryUnits'),
  memorial: noun('noun.memorial', 'noun.memorials'),
  place: noun('noun.place', 'noun.places'),
  project: noun('noun.project', 'noun.projects'),
  user: noun('noun.user', 'noun.users'),
  result: noun('noun.result', 'noun.results'),
  personFeedback: noun('noun.personFeedback', 'noun.personFeedbacks'),
  contactPerson: noun('noun.contactPerson', 'noun.contactPersons'),
  contactPersonEvent: noun('noun.contactPersonEvent', 'noun.contactPersonEvents'),
  contactMoment: noun('noun.contactMoment', 'noun.contactMoments'),
  personEvent: noun('noun.personEvent', 'noun.personEvents'),
  personStory: noun('noun.personStory', 'noun.personStories'),
};
export type Noun = keyof typeof nouns;

var languageCodes: Language[] = ['en', 'nl', 'fr', 'de'];

export const getActiveLanguage = () => {
  const storedLanguage =  localStorage.getItem('language') as Language;
  if (storedLanguage === null || storedLanguage === undefined) {
    const browserLanguage = navigator.language;
    if (browserLanguage) {
      // languages can be in format en-US. We only need first part
      const splittedLanguage = browserLanguage.split('-');
      const languageString = splittedLanguage[0];
      try {
        const language = languageString.toString().trim().toLowerCase() as Language
        if (languageCodes.includes(language)) {
          return language
        } else {
          // as the language is not one of the standard supported languages,
          // we think `en` might the most appropriate.
          return 'en' as Language
        }
      } catch (e) {
        return 'nl' as Language
      }
    } else {
      return 'nl' as Language
    }
  } else {
    return storedLanguage;
  }

}
var selectedLanguage = languages[getActiveLanguage()];

export const setLanguage = (langcode: Language) => {
  selectedLanguage = languages[langcode];
  localStorage.setItem('language', langcode);
  moment.locale(langcode);
}

export const getLanguages = () => languageCodes;
export const getUILanguages = () => ['nl', 'en', 'fr', 'de'] as Language[];

export const T = (key: TranslationKey, parameters?: {[key: string]: string}) => {
  let result = (selectedLanguage[key] || key) as string;
  if (parameters !== undefined) {
    for (var param in parameters) {
      result = result.replace(`{${param}}`, parameters[param]);
    }
  }
  return result;
}

export const singular = (noun: Noun) => {
  return T(nouns[noun].singular);
}

export const plural = (noun: Noun) => {
  return T(nouns[noun].plural);
}

export const pluralize = (noun: Noun, amount: number) => {
  return amount === 1 ? singular(noun) : plural(noun);
}

export const listOf = (items: string[]) => {
  if (items.length === 0)
    return '';
  if (items.length === 1)
    return items[0];

  return T('and', {
    a: items.slice(0, items.length - 1).join(', '),
    b: items[items.length - 1]
  });
}
