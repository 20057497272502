import React, { CSSProperties, ReactNode } from 'react';
import { Icon } from './Icons';
import { classes } from '../utils/Styles';
import { Card } from 'react-bootstrap';
import { isChildrenEmpty } from '../utils/ReactUtils';
import { CardHeader } from './CardHeader';


interface FormCardProps {
  icon: Icon;
  title: string;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
  buttons?: ReactNode;
}
export const FormCard = (props: FormCardProps) => {
  const { icon, title, className, style, children, buttons } = props;
  const empty = isChildrenEmpty(children);
  const bodyStyle: CSSProperties|undefined = empty ? { paddingTop: 5 } : undefined;

  return (
    <Card className={classes('form-horizontal', className)} style={style}>
      <CardHeader title={title} icon={icon} buttons={buttons} />
      <Card.Body style={bodyStyle}>
        {children}
      </Card.Body>
    </Card>
  )
};
