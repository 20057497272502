import React, { useMemo, useState, useCallback } from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';
import { TextInput } from '../../components/inputs/TextInput';
import { SelectInput } from '../../components/inputs/SelectInput';
import { COUNTRY_SELECT_OPTIONS } from '../../Countries';
import {
  Place,
  PlaceType,
  PlaceTypes,
  getPlaceTypeName,
  ResolutionStatus,
  toLatLng,
  IPlace,
  IPlaceSearchResultEntry,
  IPlaceLanguage
} from '../../models/Place';
import { getLanguageStringForLanguage, T } from '../../Translate';
import api from '../../api';
import { PlaceSearchInput } from './PlaceSearchInput';
import { ButtonLink } from '../../components/ButtonLink';
import { useDelayedEffect } from '../../utils/DelayedEffect';
import { SortDirection } from '../../components/table/Table';
import { PageID } from '../../utils/PageLinking';
import PageLink from '../../components/PageLink';
import { Colors, TextStyles } from "../../config/styles";
import { LanguageSelector } from "../EditPersonStory/Stories/LanguageSelector";
import { NotificationManager } from "react-notifications";


export interface PlaceFormState {
  resolutionStatus: ResolutionStatus;
  country: string;
  type: PlaceType;
  alternativeNames: string;
  location?: { lat: number, lng: number };
  languages: {[language: string]: IPlaceLanguage};

  suggestions: IPlace[];
  similar: IPlace[];
}

interface PlaceFormProps {
  placeId?: string
  formState: PlaceFormState;
  setFormState: (transform: (state: PlaceFormState) => PlaceFormState) => void;
  onClickedSimilar?: (place: IPlace) => void;
}


export const PlaceForm = (props: PlaceFormProps) => {
  const { formState, setFormState, onClickedSimilar } = props;

  const [currentLanguage, setCurrentLanguage] = useState<string>('nl')


  const placeTypes = useMemo(
    () => PlaceTypes.map(type => (
      <option key={type} value={type}>{getPlaceTypeName(type)}</option>
    )),
    []
  );
  const googleMatchCounter = useMemo<[number]>(() => [0], []);
  const [geolocationError, setGeolocationError] = useState<string>();

  const {
    resolutionStatus,
    country,
    type,
    alternativeNames,
    languages,
    suggestions,
    similar
  } = formState;

  const updatePlace = useCallback((update: Partial<PlaceFormState>) => {
    setFormState(state => {
      if (update.country || update.type || update.languages) {
        if (state.resolutionStatus !== ResolutionStatus.Custom) {
          update.resolutionStatus = ResolutionStatus.Custom;
        }
      }
      const newObject = Object.assign({}, state, update);
      return newObject
    });
  }, [setFormState]);


  const updatePlaceLanguage = useCallback((language: string, update: Partial<IPlaceLanguage>) => {
    setFormState(state => {
      const newLanguageInfo = Object.assign({}, state.languages[language], update);
      const newLanguages = Object.assign({}, state.languages, {[language]: newLanguageInfo});
      const newObject = Object.assign({}, state, {languages: newLanguages, resolutionStatus: ResolutionStatus.Custom});
      return newObject
    });
  }, [setFormState]);



  useDelayedEffect(() => {
    if (!languages[currentLanguage].name) {
      updatePlace({ similar: [] });
      return;
    }

    api.findPlaces(
      languages[currentLanguage].name,
      0, 10,
      [['filter', SortDirection.Up]],
      undefined,
      undefined,
      { country_code: country }
    ).then(places => {
      const similarWithoutCurrent = places.data.filter( similarPlace =>
      {
        return similarPlace._id !== props.placeId
      })
      updatePlace({ similar: similarWithoutCurrent })
    });
  }, [languages[currentLanguage].name, country], 500);

  const setSuggestions = (suggestions: IPlace[]) => {
    updatePlace({ suggestions });
  };



  const handleNameChanged = (newName: string) => {
    updatePlaceLanguage(currentLanguage, {name: newName})
  }

  const handleCountryChanged = (value: string) => {
    updatePlace({ country: value });
  }

  const handleTypeChanged = (value: string) => {
    updatePlace({ type: value as PlaceType });
  }

  const handleAlternativeNamesChanged = (alternativeNames: string) => {
    updatePlace({ alternativeNames });
  }

  const handleSublocalityChanged = (sublocality: string) => {
    updatePlaceLanguage(currentLanguage, {'sublocality': sublocality})
  }

  const handleLocalityChanged = (locality: string) => {
    updatePlaceLanguage(currentLanguage, {'locality': locality})
  }

  const handleArea2Changed = (area2: string) => {
    updatePlaceLanguage(currentLanguage, {'administrative_area_level_2': area2})
  }

  const handleArea1Changed = (area1: string) => {
    updatePlaceLanguage(currentLanguage, {'administrative_area_level_1': area1})
  }

  const handleCopyToOtherLanguages = () => {
    setFormState( state => {
      let languages = state.languages
      const allLanguages = ['nl', 'fr', 'en', 'de']
      for (var index in allLanguages) {
        const language = allLanguages[index]
        languages[language] = state.languages[currentLanguage]
      }
      return Object.assign({}, state, {languages: languages});
    })
    NotificationManager.success(T('page.editPlace.notificatons.data.copied'));
  }

  const handleClickedGoogle = async () => {
    const result = await api.googleMapsAPI(languages[currentLanguage].name, country, Place.getPrimaryLanguage(country));
    let googleSuggestions: IPlace[] = []
    googleSuggestions = googleSuggestions.concat(result.match.map(suggestion => convertGoogleMatch(suggestion, undefined)))
    googleSuggestions = googleSuggestions.concat(result.suggestions.map(suggestion => convertGoogleMatch(suggestion, undefined)))
    setSuggestions(googleSuggestions);
    const noResults = googleSuggestions.length === 0;
    setGeolocationError(noResults ? T('page.editPlace.noResults') : undefined);
  };

  const handleClickedGeonames = async () => {
    const result = await api.geonamesAPI(languages[currentLanguage].name, country, Place.getPrimaryLanguage(country))
    if (result.match.length > 0) {
      setSuggestions(result.match);
    } else {
      setSuggestions(result.suggestions);
    }
    const noResults = result.suggestions.length === 0 && result.match.length === 0;
    setGeolocationError(noResults ? T('page.editPlace.noResults') : undefined);
  };

  const handleClickedSuggestion = async (suggestion: IPlace) => {
    try {
      const allLanguages = ['nl', 'fr', 'de', 'en']
      for (var index in allLanguages ) {
        let suggestion: IPlace | undefined = undefined
        let language = allLanguages[index];
        const result = await api.googleMapsAPI(languages[currentLanguage].name, country, language);
        if (result.match.length > 0)
          suggestion = convertGoogleMatch(result.match[0], language);
        else if (result.suggestions.length > 0)
          suggestion = convertGoogleMatch(result.suggestions[0], language);
        if (suggestion)
          loadMatchForLanguage(suggestion);
      }
      setFormState(state=> {
        return Object.assign({}, state, {resolutionStatus: ResolutionStatus.Found})
      })
      NotificationManager.success(T('page.editPlace.notificatons.google.names.loaded'));
    } catch (e) {
      NotificationManager.error(T('page.editPlace.notificatons.google.names.failed'));
    }
  };

  const convertGoogleMatch = (match: IPlaceSearchResultEntry, language: string | undefined ): IPlace => {
    const primaryLanguage = language || Place.getPrimaryLanguage(match.address.country_code);
    return {
      _id: 'google' + (googleMatchCounter[0]++),
      type: match.type,
      country_code: match.address.country_code,
      languages: {
        [primaryLanguage]: {
          name: match.name,
          sublocality: match.address.sublocality || null,
          locality: match.address.locality || null,
          administrative_area_level_2: match.address.administrative_area_level_2 || null,
          administrative_area_level_1: match.address.administrative_area_level_1 || null,
          country: match.address.country
        }
      },
      alternative_names: [],
      resolution_status: ResolutionStatus.Found,
      loc: { lat: match.location[0], lon: match.location[1] }
    };
  }

  const loadMatchForLanguage = (match: IPlace) => {
    setFormState(state => {
      let newLanguages = Object.assign({}, state.languages, match.languages )
      return ({
        resolutionStatus: state.resolutionStatus,
        country: state.country,
        type: state.type,
        alternativeNames: state.alternativeNames,
        location: toLatLng(match.loc),
        languages: newLanguages,
        suggestions: [],
        similar
    })});
  };

  return (<>
    <div>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
        <div style={{flexGrow: 1, textAlign: "right", marginBottom: 18}}>
          <LanguageSelector onUpdateLanguage={(language) => {setCurrentLanguage(language.toString())}}/>
        </div>
      </div>
    </div>
    <Form>
      <Card style={{backgroundColor: Colors.verylightGray, paddingTop: 10, paddingRight: 18}}>
        <PlaceSearchInput
          name='name'
          value={languages[currentLanguage].name ?? ''}
          onChange={handleNameChanged}
          resolutionStatus={resolutionStatus}
          onClickedGoogle={handleClickedGoogle}
          onClickedGeonames={handleClickedGeonames}
          error={geolocationError}
        />
        <SelectInput
          name='country'
          label={T('modal.editPlace.country')}
          labelColumns={3}
          value={country}
          onChange={handleCountryChanged}
          options={COUNTRY_SELECT_OPTIONS}
          controlStyle={{paddingLeft: 10}}
        />
      </Card>
      {suggestions.length > 0 && <Row>
        <Col sm={{ span: 9, offset: 3 }}>
          <p style={{...TextStyles.text.medium}}>{T('page.editPlace.suggestions')}</p>
          <ul>
            {suggestions.map(place => (
              <li key={place._id}>
                <ButtonLink style={{...TextStyles.link.medium}} onClick={() => handleClickedSuggestion(place)}>
                  {new Place(place).describe()}
                </ButtonLink>
              </li>
            ))}
          </ul>
        </Col>
      </Row>}
      {similar.length > 0 && <Row>
        <Col sm={{ span: 9, offset: 3 }}>
          <p style={{...TextStyles.text.medium}}>{T('page.editPlace.similar')}</p>
          <ul>
            {similar.map(place => (
              <li key={place._id}>
                {onClickedSimilar ? (
                  <ButtonLink style={{...TextStyles.link.medium}} onClick={() => onClickedSimilar(place)}>
                    {new Place(place).describe()}
                  </ButtonLink>
                ) : (
                  <PageLink style={{...TextStyles.link.medium}} page={PageID.ViewPlace} param={place._id} target='_blank'>
                    {new Place(place).describe()}
                  </PageLink>
                )}
              </li>
            ))}
          </ul>
        </Col>
      </Row>}
      <SelectInput
        name='type'
        label={T('place.type')}
        labelColumns={3}
        value={type}
        onChange={handleTypeChanged}
        optionElements={placeTypes}
      />
      <TextInput
        name='alternative-names'
        label={T('place.alternativeNames')}
        labelColumns={3}
        value={alternativeNames}
        onChange={handleAlternativeNamesChanged}
      />
      <TextInput
        name='sublocality'
        label={T('place.type.sublocality')}
        labelColumns={3}
        value={languages[currentLanguage].sublocality ?? ''}
        onChange={handleSublocalityChanged}
      />
      <TextInput
        name='locality'
        label={T('place.type.locality')}
        labelColumns={3}
        value={languages[currentLanguage].locality ?? ''}
        onChange={handleLocalityChanged}
      />
      <TextInput
        name='area-2'
        label={T('place.type.area_2')}
        labelColumns={3}
        value={languages[currentLanguage].administrative_area_level_2 ?? ''}
        onChange={handleArea2Changed}
      />
      <TextInput
        name='area-1'
        label={T('place.type.area_1')}
        labelColumns={3}
        value={languages[currentLanguage].administrative_area_level_1 ?? ''}
        onChange={handleArea1Changed}
      />
    </Form>
    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
      <div style={{flexGrow: 1, textAlign: "right"}}>
        <ButtonLink onClick={handleCopyToOtherLanguages}>
          <span style={TextStyles.link.medium}>{T('page.editPlace.copy', {language: getLanguageStringForLanguage(currentLanguage)})}</span>
        </ButtonLink>
      </div>
    </div>
    </>
  );
}
