import React from 'react';
import {
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { useElementID } from '../../utils/ElementID';

export interface ILatLon {
  lat: number;
  lng: number;
}
interface MapMarkerInputProps {
  labelColumns?: number;

  label: string;
  position?: ILatLon;
  defaultCenter?: ILatLon;
  info?: string;
  readOnly?: boolean;

  onChange: (position: ILatLon) => void;
}

export const MapMarkerInputMap = withGoogleMap((props: MapMarkerInputProps) => {
  const {
    position,
    defaultCenter = position,
    onChange,
    readOnly = false
  } = props;

  const handleDragEnd = (e: any) => {
    if (readOnly)
      return;
    
    onChange({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  const handleClick = (e: any) => {
    if (readOnly)
      return;
    
    onChange({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  return (
    <GoogleMap defaultZoom={8} center={defaultCenter} onClick={position ? undefined : handleClick}>
      {position && (
        <Marker
          position={position}
          draggable={!readOnly}
          onDragEnd={handleDragEnd}
        />
      )}
    </GoogleMap>
  );
});

export const MapMarkerInput = (props: MapMarkerInputProps) => {
  const {
    labelColumns = 4,
    label,
    info,
    readOnly
  } = props;

  const id = useElementID('input');
  return (
    <div className="form-group row">
      <label htmlFor={id} className={`col-sm-${labelColumns} control-label`}>{label}</label>
      <div className={`col-sm-${12 - labelColumns}`}>
        <MapMarkerInputMap
          containerElement={<div style={{ height: 300 }} />}
          mapElement={<div style={{ height: `100%` }} />}
          readOnly={readOnly}
          {...props}
        />
        {info && <small className='form-text text-muted' style={{textAlign: "right"}}>{info}</small>}
      </div>
    </div>
  );
}
