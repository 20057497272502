import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import { ILatLon } from './MapMarkerInput';
import { useElementID } from '../../utils/ElementID';
import FormGroup from './FormGroup';

interface MapCoordinatesInputProps {
  labelColumns?: number;

  name: string;
  label: string;
  position?: ILatLon;
  placeholderLat?: string;
  placeholderLon?: string;
  error?: string;
  disabled?: boolean;

  /**
   * callback when lat & lon changes
   * @param place is undefined if lat and lon are empty
   */
  onChange: (place: ILatLon|undefined) => void;
}

export const MapCoordinatesInput = (props: MapCoordinatesInputProps) => {
  const {
    labelColumns = 4,
    name,
    label,
    position,
    placeholderLat,
    placeholderLon,
    onChange,
    error,
    disabled
  } = props;

  const [latInput, setLatInput] = useState(position ? position.lat.toFixed(4) : '');
  const [lngInput, setLngInput] = useState(position ? position.lng.toFixed(4) : '');
  const [lastPosition, setLastPosition] = useState(position);

  useEffect(() => {
    if ((position === undefined) !== (lastPosition === undefined)) {
      setLatInput(position ? position.lat.toFixed(4) : '');
      setLngInput(position ? position.lng.toFixed(4) : '');
    } else if (position !== undefined) {
      const floatLatInput = parseFloat(latInput);
      const floatLngInput = parseFloat(lngInput);
      if (!isNaN(floatLatInput) && Math.abs(floatLatInput - position.lat) > 0.0001) {
        setLatInput(position.lat.toFixed(4));
      }
      if (!isNaN(floatLngInput) && Math.abs(floatLngInput - position.lng) > 0.0001) {
        setLngInput(position.lng.toFixed(4));
      }
    } else {
      setLatInput('');
      setLngInput('');
    }

    setLastPosition(position);
  }, [position]);

  const handleLatChanged = (value: string) => {
    setLatInput(value);

    const latNumber = parseFloat(value);
    const lonNumber = parseFloat(lngInput);
    if (!Number.isNaN(latNumber) && !Number.isNaN(lonNumber))
      onChange({ lat: latNumber, lng: lonNumber });
    else if (value === '' && lngInput === '')
      onChange(undefined);
  }

  const handleLngChanged = (value: string) => {
    setLngInput(value);

    const latNumber = parseFloat(latInput);
    const lonNumber = parseFloat(value);
    if (!Number.isNaN(latNumber) && !Number.isNaN(lonNumber))
      onChange({ lat: latNumber, lng: lonNumber });
    else if (latInput === '' && value === '')
      onChange(undefined);
  }

  const id = useElementID('input');
  return (
    <FormGroup id={id} labelColumns={labelColumns} label={label} error={error}>
      <div className='row'>
        <div className={`col-sm-6`}>
          <Form.Control
            id={id + '-lat'}
            name={name + '-lat'}
            type="text"
            className='form-control'
            placeholder={placeholderLat}
            value={latInput}
            onChange={e => handleLatChanged(e.currentTarget.value)}
            isInvalid={error !== undefined}
            disabled={disabled}
          />
        </div>
        <div className={`col-sm-6`}>
          <Form.Control
            id={id + '-lng'}
            name={name + '-lng'}
            type="text"
            className='form-control'
            placeholder={placeholderLon}
            value={lngInput}
            onChange={e => handleLngChanged(e.currentTarget.value)}
            isInvalid={error !== undefined}
            disabled={disabled}
          />
        </div>
      </div>
    </FormGroup>
  );
};
