import React, { CSSProperties, useRef } from 'react';
import { InvalidFeedback } from './InvalidFeedback';
import { useElementID } from '../../utils/ElementID';

export const emptyDate = () => ({ day: '', month: '', year: '' });

export interface DateInputDate {
  day: string;
  month: string;
  year: string;
}
export function inputDateToSortable(date: DateInputDate) {
  const yearPart = date.year === '' ? '????' : date.year.toString().padStart(4, '0');
  const monthPart = date.month === '' ? '??' : date.month.toString().padStart(2, '0');
  const dayPart = date.day === '' ? '??' : date.day.toString().padStart(2, '0');
  return yearPart + monthPart + dayPart;
}
export function parseInputDateFromSortable(date: string): DateInputDate {
  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const day = date.substring(6, 8);
  return {
    year: year === '????' ? '' : year,
    month: month === '??' ? '' : month,
    day: day === '??' ? '' : day
  };
}

interface DateInputProps {
  name: string;
  label: string;
  value: DateInputDate;
  onChange: (date: DateInputDate) => void;
  style?: CSSProperties;

  labelColumns?: number;
  error?: string;
  readOnly?: boolean;
  validated?: boolean;
}
export const DateInput = (props: DateInputProps) => {
  const {
    name,
    labelColumns = 4,
    label,
    value,
    error,
    readOnly,
    validated,
    style,
    onChange,
  } = props;

  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const isDigit = (value:string):Boolean => {
    const numberValue = Number(value)
    return !isNaN(numberValue);
  }

  const handleDayChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;
    if (v.length > 2)
      return;

    if (!isDigit(v))
      return;

    const monthElement = monthRef.current;
    onChange(Object.assign({}, value, { day: v }));

    if (v.length === 2 && monthElement)
      monthElement.focus();
  };

  const handleMonthChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;
    if (v.length > 2)
      return;

    if (!isDigit(v))
      return;

    const yearElement = yearRef.current;
    onChange(Object.assign({}, value, { month: v }));

    if (v.length === 2 && yearElement)
      yearElement.focus();
  };

  const handleYearChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;
    if (v.length > 4)
      return;

    if (!isDigit(v))
      return;

    onChange(Object.assign({}, value, { year: v }));
  };

  const id = useElementID('dateInput');
  const inputClass = error ? 'form-control is-invalid' : (validated ? 'form-control is-valid' : 'form-control');
  return (
    <div className="form-group row" style={style}>
      <label htmlFor={id} className={`col-sm-${labelColumns} control-label`}>{label}:</label>
      <div className={`col-sm-${12 - labelColumns}`}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input
            name={name + '-d'}
            className={inputClass}
            style={{ width: 60, marginRight: 10 }}
            value={value.day}
            onChange={handleDayChanged}
            placeholder='DD'
            disabled={readOnly}
          />
          <input
            name={name + '-m'}
            className={inputClass}
            style={{ width: 60, marginRight: 10 }}
            value={value.month}
            onChange={handleMonthChanged}
            placeholder='MM'
            ref={monthRef}
            disabled={readOnly}
          />
          <input
            name={name + '-y'}
            className={inputClass}
            style={{ width: 80 }}
            value={value.year}
            onChange={handleYearChanged}
            placeholder='YYYY'
            ref={yearRef}
            disabled={readOnly}
          />
        </div>
        {error && <InvalidFeedback error={error} />}
      </div>
    </div>
  );
};
