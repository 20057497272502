
import React, { CSSProperties, ReactNode } from 'react';
import { Language } from '../../Translate';
import AppBar from './AppBar';

import { History } from 'history';
import { AppSideBar } from './AppSideBar';
import { PageInfo, PageLayout } from '../../pages/Page';
import NoLoginAppBar from './NoLoginAppBar';
import { NotificationContainer } from 'react-notifications';
import { PublicLayout } from './public/PublicLayout';

interface LayoutProps {
  history: History;
  page: PageInfo;
  onLanguageChanged: (language: Language) => void;
  layout?: PageLayout;
  children: ReactNode;
}
export const Layout = (props: LayoutProps) => {
  const { layout = PageLayout.Regular } = props;
  switch (layout) {
    case PageLayout.NoFrames:
      return <NoFramesLayout {...props} />;
    case PageLayout.Public:
      return <PublicLayout {...props} />;
    case PageLayout.PublicPerson:
      return <PublicLayout {...props} person={true} />;
    case PageLayout.PublicPersonKey:
      return <PublicLayout {...props} person={true} ypresPeaceLogo={true} />;
    default:
      return <RegularLayout {...props} />;
  }
}

const RegularLayout = (props: LayoutProps) => {
  const { history, page, onLanguageChanged, children } = props;
  const mainPanelStyle: CSSProperties = {};

  return (
    <div className='wrapper'>
      <AppSideBar />
      <NotificationContainer />
      <div className='main-panel' style={mainPanelStyle}>
        <AppBar
          history={history}
          loggedIn={false}
          page={page}
          onLanguageChanged={onLanguageChanged}
        />
        {children}
      </div>
    </div>
  );
}

const NoFramesLayout = (props: LayoutProps) => {
  const { history, page, onLanguageChanged, children } = props;
  return (
    <div className='wrapper'>
      <NotificationContainer />
      <div className='main-panel' style={{ width: '100%' }}>
        <NoLoginAppBar
          history={history}
          loggedIn={false}
          page={page}
          onLanguageChanged={onLanguageChanged}
        />
        {children}
      </div>
    </div>
  );
}
