import React from 'react';
import { BasePage, PageProps, PageState } from '../Page';
import { Icon } from '../../components/Icons';
import { Row, Col, Form, FormGroup, Button, Card, Alert } from 'react-bootstrap';
import { TextInput } from '../../components/inputs/TextInput';
import { SelectInput, ISelectOption } from '../../components/inputs/SelectInput';
import {
  Gender,
  VictimType,
  VictimSubType,
  IPerson,
  PersonRelationType,
  PersonQueryOptions,
  IPersonFields,
  IPersonProjectMembership, Person
} from '../../models/Person';
import { DateInput, DateInputDate, emptyDate, inputDateToSortable } from '../../components/inputs/DateInput';
import { FormCard } from '../../components/FormCard';
import { RadioInput } from '../../components/inputs/RadioInput';
import { TopTabs, TopTab } from '../../components/TopTabs';
import { TextareaInput } from '../../components/inputs/TextareaInput';
import { ReferenceInput } from '../../components/inputs/ReferenceTypeaheadInput';
import { DocumentReferenceType, DocumentReference } from '../../models/DocumentReference';
import {
  PersonEvent,
  PersonEventType,
  createNewEvent,
  personEventSorter, PersonEventLoadOption
} from '../../models/PersonEvent';
import { IPersonRelation } from '../../models/Person';

import { IPersonDocument, IPersonDocumentFields } from '../../models/PersonDocument';
import { EditEventModal } from './EditEventModal';
import { T, singular, plural } from '../../Translate';
import { LinkedPersonView } from './LinkedPersonView';
import api from '../../api';
import { ConfirmationModal } from '../../modals/Confirmation';
import { IValidationResult, getErrorDescription } from '../../models/ValidationResult';
import { PersonEventsTab } from './PersonEventsTab';
import { showNotification } from '../../utils/Notification';
import { IAttachment } from '../../models/Attachment';
import { IProject } from '../../models/Project';
import { PersonProjectsTab } from './PersonProjectsTab';
import { ActiveContactMoment } from '../../models/ActiveContactMoment';
import { connect } from 'react-redux';
import { AppState } from '../../redux/State';
import { Dispatch } from 'redux';
import { AnyAppAction, registerContactMomentContributionAction } from '../../redux/Actions';
import { ContactMomentContribution } from '../../models/ContactPerson';
import { BottomButtonRow } from '../../components/ButtonRow';
import { NotificationManager } from 'react-notifications';
import { PageID, getPageVia } from '../../utils/PageLinking';
import { IPersonStory2 } from "../../models/PersonStory2";
import { PersonStoriesTab } from "./PersonStoriesTab";
import ListDocumentsTab from "../ViewPlace/ListDocumentsTab";
import EmptyMessage from "./components/EmptyMessage";


interface EditPersonOwnProps extends PageProps {
  id?: string;
}
interface EditPersonStateProps {
  contactMoment?: ActiveContactMoment;
}
interface EditPersonDispatchProps {
  registerContribution: (contribution: ContactMomentContribution) => void;
}
type EditPersonProps = EditPersonOwnProps & EditPersonStateProps & EditPersonDispatchProps;

interface EditPersonState extends PageState {
  person?: IPerson;

  firstName: string;
  firstNameError?: string;
  lastName: string;
  lastNameError?: string;
  firstNameAlt: string;
  firstNameAltError?: string;
  lastNameAlt: string;
  lastNameAltError?: string;
  gender: Gender;
  genderError?: string;
  bornDate: DateInputDate;
  bornDateError?: string;
  diedDate: DateInputDate;
  diedDateError?: string;
  age: string;
  ageError?: string;
  warCasualty: boolean;
  warCasualtyError?: string;
  victimType: VictimType;
  victimTypeError?: string;
  victimSubType: VictimSubType;
  victimSubTypeError?: string;
  isNamelister: boolean;
  isNamelisterError?: string;
  isIMR: boolean; // Irish Memorial Records (=> see the memorial)
  isIMRError?: string;
  familyInfo: string;
  familyInfoError?: string;
  relations: IPersonRelation[];
  projectMemberships: IPersonProjectMembership[];

  events: PersonEvent[];
  documents: IPersonDocument[];
  projects: IProject[];
  personStories: IPersonStory2[];

  /// The number of document related to this person
  numberOfDocuments: number
}

const IMR_MEMORIAL_ID = '1c22d351-52e4-4067-bffa-778672073e99';

class EditPersonPage extends BasePage<EditPersonProps, EditPersonState> {
  genderOptions: ISelectOption[];
  warCasualtyOptions: ISelectOption[];
  victimTypeOptions: ISelectOption[];
  victimSubTypeOptions: ISelectOption[];
  namelisterOptions: ISelectOption[];
  isIMROptions: ISelectOption[];

  constructor(props: EditPersonProps) {
    super(props);

    this.genderOptions = [
      { value: Gender.Male, title: T('gender.male') },
      { value: Gender.Female, title: T('gender.female') },
      { value: Gender.Undefined, title: T('gender.undefined') }
    ];
    this.warCasualtyOptions = [
      { value: 'N', title: T('person.survivor') },
      { value: 'Y', title: T('person.victim') }
    ];
    this.victimTypeOptions = [
      { value: VictimType.Military, title: T('victimType.military')},
      { value: VictimType.Civilian, title: T('victimType.civilian') }
    ];
    this.victimSubTypeOptions = [
      { value: VictimSubType.Unknown, title: T('victimSubType.unknown') },
      { value: VictimSubType.Deportated, title: T('victimSubType.deportated') },
      { value: VictimSubType.Executed, title: T('victimSubType.executed') }
    ];
    this.namelisterOptions = [
      { value: 'Y', title: T('yes') },
      { value: 'N', title: T('no') }
    ];
    this.isIMROptions = [
      { value: 'Y', title: T('person.isIMR.yes') },
      { value: 'N', title: T('person.isIMR.no') }
    ];

    this.state = {
      page: {
        id: props.id ? PageID.EditPerson : PageID.CreatePerson,
        entity: props.id,
        icon: Icon.User,
        title: T('page.person.title.create'),
        via: props.via || 'persons'
      },

      firstName: '',
      lastName: '',
      firstNameAlt: '',
      lastNameAlt: '',
      gender: Gender.Undefined,
      bornDate: emptyDate(),
      diedDate: emptyDate(),
      age: '',
      warCasualty: false,
      isNamelister: true,
      isIMR: false,

      victimType: VictimType.Civilian,
      victimSubType: VictimSubType.Unknown,
      familyInfo: '',
      relations: [],

      projectMemberships: [],

      events: [],
      documents: [],
      projects: [],
      personStories:[],

      numberOfDocuments: 0
    };
  }

  /*** life cycle ***/

  async componentDidMount() {

    super.componentDidMount();
    await this.loadProjects();
    this.loadNumberOfDocuments();
  }

  async componentDidUpdate(oldProps: EditPersonProps, oldState: EditPersonState) {
    super.componentDidUpdate(oldProps, oldState);
    if (oldProps.id !== this.props.id) {
      if (this.props.id) {
        this.loadContent();
      } else {
        this.clear();
      }
    }

    if (this.state.person?._id !== oldState.person?._id) {
      this.loadNumberOfDocuments();
    }
  }

  clear() {
    this.setState({
      page: {
        id: this.props.id ? PageID.EditPerson : PageID.CreatePerson,
        entity: this.props.id,
        icon: Icon.User,
        title: T('page.person.title.create'),
        via: this.props.via || 'persons'
      },

      firstName: '',
      firstNameError: undefined,
      lastName: '',
      lastNameError: undefined,
      firstNameAlt: '',
      firstNameAltError: undefined,
      lastNameAlt: '',
      lastNameAltError: undefined,
      gender: Gender.Undefined,
      genderError: undefined,
      bornDate: emptyDate(),
      bornDateError: undefined,
      diedDate: emptyDate(),
      diedDateError: undefined,
      age: '',
      ageError: undefined,
      warCasualty: false,
      warCasualtyError: undefined,
      isNamelister: true,
      isNamelisterError: undefined,
      isIMR: false,
      isIMRError: undefined,

      victimType: VictimType.Civilian,
      victimTypeError: undefined,
      victimSubType: VictimSubType.Unknown,
      victimSubTypeError: undefined,
      familyInfo: '',
      familyInfoError: undefined,

      projectMemberships: [],

      relations: [],
      events: [],
      documents: []
    });
  }

  loadProjects() {
    this.loading(api.findProjects(undefined, undefined, undefined, []), plural('project'))
      .then(projects => this.setState({ projects: projects.data }));
  }

  loadPersonStories() {
    if (this.state.person === undefined) {
      return;
    }


    this.loading(api.findPersonStoriesForPerson(this.state.person._id), plural('person'))
      .then(personstories => {
        this.setState({ personStories: personstories.data })
      });
  }

  loadContent() {
    const { id, via } = this.props;
    if (!id)
      return;

    this.loading(api.getPerson(
      id,
      [PersonQueryOptions.EventsWithPlaces, PersonQueryOptions.Documents, PersonQueryOptions.RelationPersons]
    ), singular('person')).then(person => {
      const events = person.events || [];
      events.sort(personEventSorter);
      const name = person.surname + ' ' + person.familyname.toUpperCase();
      this.setState({
        page: {
          id: this.props.id ? PageID.EditPerson : PageID.CreatePerson,
          entity: this.props.id,
          icon: Icon.User,
          title: T('page.person.title.edit', { name }),
          shortTitle: T('generic.breadcrumb.edit'),
          via: via || 'person-' + id,
          viaTitle: name
        },
        person,

        firstName: person.surname,
        firstNameError: undefined,
        lastName: person.familyname,
        lastNameError: undefined,
        firstNameAlt: person.alternative_surnames.join(','),
        firstNameAltError: undefined,
        lastNameAlt: person.alternative_familynames.join(','),
        lastNameAltError: undefined,
        gender: person.gender,
        genderError: undefined,
        bornDate: {
          day: person.born_day || '',
          month: person.born_month || '',
          year: person.born_year || ''
        },
        bornDateError: undefined,
        diedDate: {
          day: person.died_day || '',
          month: person.died_month || '',
          year: person.died_year || ''
        },
        diedDateError: undefined,
        age: person.died_age === 0 ? '' : person.died_age.toString(),
        ageError: undefined,
        warCasualty: person.war_casualty,
        warCasualtyError: undefined,
        isNamelister: person.in_namelist,
        isNamelisterError: undefined,
        isIMR: person.memorials.includes(IMR_MEMORIAL_ID),
        isIMRError: undefined,

        victimType: person.victim_type,
        victimTypeError: undefined,
        victimSubType: person.victim_type_details as VictimSubType,
        victimSubTypeError: undefined,
        familyInfo: person.description,
        familyInfoError: undefined,

        projectMemberships: person.project_memberships,

        relations: person.relations,
        events: person.events || [],
        documents: person.documents || []
      });
      this.loadPersonStories();
    });
  }

  loadNumberOfDocuments = async () => {
    if (!this.state.person?._id)
      return

    const count = await api.countDocumentsRelatedTo('person', this.state.person._id);
    this.setState({...this.state, numberOfDocuments: count})
  }

  handleFirstNameChanged = (firstName: string) => {
    this.setState({
      firstName,
      firstNameError: undefined
    });
  }

  handleLastNameChanged = (lastName: string) => {
    this.setState({
      lastName,
      lastNameError: undefined
    });
  }

  handleFirstNameAltChanged = (firstNameAlt: string) => {
    this.setState({
      firstNameAlt,
      firstNameError: undefined
    });
  }

  handleLastNameAltChanged = (lastNameAlt: string) => {
    this.setState({
      lastNameAlt,
      lastNameAltError: undefined
    });
  }

  handleGenderChanged = (value: string) => {
    this.setState({
      gender: value as Gender,
      genderError: undefined
    });
  }

  handleBornDateChanged = (value: DateInputDate) => {
    this.setState({
      bornDate: value,
      bornDateError: undefined
    });
  }

  handleDiedDateChanged = (value: DateInputDate) => {
    this.setState({
      diedDate: value,
      diedDateError: undefined
    });
  }

  handleAgeChanged = (age: string) => {
    this.setState({
      age,
      ageError: undefined
    });
  }

  handleWarCasualtyChanged = (value: string) => {
    this.setState({
      warCasualty: value === 'Y',
      warCasualtyError: undefined
    });
  }

  handleVictimTypeChanged = (value: string) => {
    this.setState({
      victimType: value as VictimType,
      victimTypeError: undefined
    });
  }

  handleVictimSubTypeChanged = (value: string) => {
    this.setState({
      victimSubType: value as VictimSubType,
      victimSubTypeError: undefined
    });
  }

  handleNamelisterChanged = (value: string) => {
    this.setState({
      isNamelister: value === 'Y',
      isNamelisterError: undefined
    });
  }

  handleIsIMRChanged = (value: string) => {
    this.setState({
      isIMR: value === 'Y',
      isIMRError: undefined
    });
  }

  handleFamilyInfoChanged = (familyInfo: string) => {
    this.setState({
      familyInfo,
      familyInfoError: undefined
    });
  }

  handleRelationSelected = (reference: DocumentReference) => {
    if (!reference.id)
      return;

    const id = reference.id;
    api.getPerson(id).then(person => {
      const { relations } = this.state;
      const newRelation: IPersonRelation = {
        person_id: id,
        person,
        relation: PersonRelationType.Other,
        comments: ''
      };
      const newRelations = [...relations, newRelation];
      this.setState({ relations: newRelations });
    });
  }

  handleRelationChanged = (index: number, relation: IPersonRelation) => {
    const { relations } = this.state;
    const newRelations = relations.map((r, i) => i === index ? relation : r);
    this.setState({ relations: newRelations });
  }

  handleClickedRemoveRelation = (index: number) => {
    const { relations } = this.state;
    const newRelations = relations.filter((r, i) => i !== index);
    this.setState({ relations: newRelations });
  }

  requestContributionRegistration(person: IPerson): Promise<IPerson> {
    const { contactMoment } = this.props;
    if (contactMoment) {
      return this.showModal<boolean>(props => (
        <ConfirmationModal
          title={T('page.person.registerContribution.title')}
          message={T('page.person.registerContribution.message')}
          {...props}
        />
      )).then(confirm => {
        if (confirm) {
          const { registerContribution } = this.props;
          registerContribution({
            type: 'person',
            targetId: person._id,
            name: person.surname + ' ' + person.familyname
          });
        }
        return person;
      });
    } else {
      return Promise.resolve(person);
    }
  }

  handleClickedSave = () => {
    this.save().then(result => {
      if (!result)
        return;

      NotificationManager.success(T('page.person.saved'));

      this.requestContributionRegistration(result).then(() => {
        const { history, id } = this.props;
        if (id === undefined) {
          // newly created person
          history.push('/persons/' + result._id);
        } else {
          // person data (eg events) may have been changed
          this.loadContent();
        }
      });
    });
  }

  handleClickedSaveAndNew = () => {
    this.save().then(result => {
      if (!result)
        return;

      NotificationManager.success(T('page.person.saved'));

      this.requestContributionRegistration(result).then(() => {
        if (this.props.id) {
          const { history } = this.props;
          history.push('/persons/create');
        } else {
          this.clear();
        }
      });
    });
  }

  save(): Promise<IPerson|undefined> {
    const {
      person,

      firstName,
      firstNameAlt,
      lastName,
      lastNameAlt,
      gender,
      victimType,
      victimSubType,
      bornDate,
      diedDate,
      age,
      warCasualty,
      isNamelister,
      isIMR,
      familyInfo,
      relations,
      projectMemberships
    } = this.state;
    if (person) {
      // update
      const updatedPerson = Object.assign({}, person, {
        surname: firstName,
        familyname: lastName,
        alternative_surnames: firstNameAlt.length > 0 ? firstNameAlt.split(',') : [],
        alternative_familynames: lastNameAlt.length > 0 ? lastNameAlt.split(',') : [],
        gender,
        victim_type: victimType,
        victim_type_details: victimSubType,
        born_day: bornDate.day,
        born_month: bornDate.month,
        born_year: bornDate.year,
        sort_born_date: inputDateToSortable(bornDate),
        died_day: diedDate.day,
        died_month: diedDate.month,
        died_year: diedDate.year,
        sort_died_date: inputDateToSortable(diedDate),
        died_age: age,
        description: familyInfo,
        relations,
        war_casualty: warCasualty,
        in_namelist: isNamelister && warCasualty,
        project_memberships: projectMemberships
      });
      if (person.memorials.includes(IMR_MEMORIAL_ID) !== isIMR) {
        // TODO: add/remove IMR
      }
      return api.validatePerson(updatedPerson, 'update').then(result => {
        if (result.status !== 'OK') {
          this.setValidationErrors(result);
          return undefined;
        }

        return api.updatePerson(updatedPerson);
      });
    } else {
      // create
      const newPerson: IPersonFields = {
        surname: firstName,
        familyname: lastName,
        alternative_surnames: firstNameAlt.split(','),
        alternative_familynames: lastNameAlt.split(','),
        gender,
        victim_type: victimType,
        victim_type_details: victimSubType,
        born_day: bornDate.day,
        born_month: bornDate.month,
        born_year: bornDate.year,
        died_day: diedDate.day,
        died_month: diedDate.month,
        died_year: diedDate.year,
        died_age: age ? parseInt(age) : 0,
        initials: '',
        description: familyInfo,
        relations,
        war_casualty: warCasualty,
        in_namelist: isNamelister && warCasualty,

        nationality: '',
        sort_born_date: inputDateToSortable(bornDate),
        sort_died_date: inputDateToSortable(diedDate),
        cwxrm_remembered: false,
        project_memberships: projectMemberships,
        memorials: []
      };
      return api.validatePerson(newPerson, 'create').then(result => {
        if (result.status !== 'OK') {
          this.setValidationErrors(result);
          return undefined;
        }

        return api.createPerson(newPerson);
      });
    }
  }

  setValidationErrors(result: IValidationResult) {
    const errors = result.data || {};
    this.setState({
      firstNameError: getErrorDescription(errors.surname),
      lastNameError: getErrorDescription(errors.familyname),
      genderError: getErrorDescription(errors.gender),
      victimTypeError: getErrorDescription(errors.victim_type),
      victimSubTypeError: getErrorDescription(errors.victim_type_details),
      bornDateError: getErrorDescription(errors.born_date),
      diedDateError: getErrorDescription(errors.died_date),
      ageError: getErrorDescription(errors.died_age),
      firstNameAltError: getErrorDescription(errors.alternative_surnames),
      lastNameAltError: getErrorDescription(errors.alternative_familynames),
      familyInfoError: getErrorDescription(errors.description)
    });
  }

  generateTabs(): TopTab[] {
    const { events, relations, projectMemberships } = this.state;
    return [
      {
        icon: Icon.User,
        title: T('page.person.tab.identity'),
        id: 'identity',
        content: this.renderIdentityTab
      },
      {
        icon: Icon.ListUl,
        title: T('page.person.tab.events'),
        id: 'events',
        content: this.renderEventsTab,
        badgeContent: () => events.length.toString()
      },
      {
        icon: Icon.Link,
        title: T('page.person.tab.relations'),
        id: 'relations',
        content: this.renderRelationsTab,
        badgeContent: () => relations.length.toString()
      },
      {
        icon: Icon.Copy,
        title: T('page.person.tab.documentation'),
        id: 'documents',
        content: this.renderDocumentsTab,
        badgeContent: () => this.state.numberOfDocuments.toString()
      },
      {
        icon: Icon.Books,
        title: T('page.person.tab.personStories'),
        id: 'personStories',
        content: this.renderPersonStoriesTab,
        badgeContent: () => this.state.personStories.length.toString()
      },
      {
        icon: Icon.Books,
        title: T('page.person.tab.projects'),
        id: 'projects',
        content: this.renderProjectsTab,
        badgeContent: () => projectMemberships.length.toString()
      },

    ];
  }

  renderIdentityForm() {
    const {
      firstName,
      firstNameError,
      lastName,
      lastNameError,
      firstNameAlt,
      firstNameAltError,
      lastNameAlt,
      lastNameAltError,
      gender,
      genderError,
      bornDate,
      bornDateError,
      diedDate,
      diedDateError,
      age,
      ageError
    } = this.state;

    return (
      <Form>
        <TextInput
          name='last-name'
          value={lastName}
          label={T('person.lastName')}
          onChange={this.handleLastNameChanged}
          error={lastNameError}
        />
        <TextInput
          name='last-name-alt'
          value={lastNameAlt}
          label={T('person.lastNameAlt')}
          labelColumns={5}
          onChange={this.handleLastNameAltChanged}
          error={lastNameAltError}
        />
        <TextInput
          name='first-name'
          value={firstName}
          label={T('person.firstName')}
          onChange={this.handleFirstNameChanged}
          error={firstNameError}
        />
        <TextInput
          name='first-name-alt'
          value={firstNameAlt}
          label={T('person.firstNameAlt')}
          labelColumns={5}
          onChange={this.handleFirstNameAltChanged}
          error={firstNameAltError}
        />
        <SelectInput
          name='gender'
          options={this.genderOptions}
          label={T('person.gender')}
          value={gender}
          onChange={this.handleGenderChanged}
          error={genderError}
        />
        <DateInput
          name='born-date'
          value={bornDate}
          label={T('person.born')}
          onChange={this.handleBornDateChanged}
          error={bornDateError}
        />
        <DateInput
          name='died-date'
          value={diedDate}
          label={T('person.died')}
          onChange={this.handleDiedDateChanged}
          error={diedDateError}
        />
        <TextInput
          name='age'
          value={age}
          label={T('person.age')}
          onChange={this.handleAgeChanged}
          error={ageError}
        />
      </Form>
    );
  }

  renderCategoryForm() {
    const {
      warCasualtyError,
      warCasualty,
      victimType,
      victimTypeError,
      victimSubType,
      victimSubTypeError,
      isNamelister,
      isNamelisterError,
      isIMR,
      isIMRError
    } = this.state;
    return (
      <Form>
        <RadioInput
          horizontal
          options={this.warCasualtyOptions}
          value={warCasualty ? 'Y' : 'N'}
          onChange={this.handleWarCasualtyChanged}
          error={warCasualtyError}
        />
        <RadioInput
          horizontal
          options={this.victimTypeOptions}
          value={victimType}
          onChange={this.handleVictimTypeChanged}
          error={victimTypeError}
        />
        {warCasualty && (
          <RadioInput
            horizontal
            label={T('person.isNamelist')}
            labelCols={6}
            options={this.namelisterOptions}
            value={isNamelister ? 'Y' : 'N'}
            valueCols={3}
            onChange={this.handleNamelisterChanged}
            error={isNamelisterError}
          />
        )}
        {warCasualty && victimType === VictimType.Military && (
          <RadioInput
            horizontal
            label={T('person.isIMR')}
            labelCols={6}
            options={this.isIMROptions}
            value={isIMR ? 'Y' : 'N'}
            valueCols={3}
            onChange={this.handleIsIMRChanged}
            error={isIMRError}
          />
        )}
        <SelectInput
          name='category'
          label={T('person.category')}
          options={this.victimSubTypeOptions}
          value={victimSubType}
          onChange={this.handleVictimSubTypeChanged}
          error={victimSubTypeError}
        />
      </Form>
    );
  }

  renderFamilyInfoForm() {
    const { familyInfo, familyInfoError } = this.state;
    return (
      <Form>
        <TextareaInput
          name='family-info'
          label={T('person.familyInfo')}
          value={familyInfo}
          onChange={this.handleFamilyInfoChanged}
          placeholder={T('person.familyInfo.placeholder')}
          vertical
          error={familyInfoError}
          rows={8}
        />
      </Form>
    );
  }

  renderRelationsForm() {
    const { relations, gender } = this.state;
    const relationViews = relations.map((relation, index) => (
      <LinkedPersonView
        key={index}
        gender={gender}
        relation={relation}
        index={index}
        onChange={this.handleRelationChanged}
        onClickedDelete={this.handleClickedRemoveRelation}
      />
    ));
    return (
      <Form>
        <Form.Row>
          <Col sm={8}>
          {relationViews}
          </Col>
          <Col sm={4}>
            <FormGroup>
              <Card style={{paddingLeft: 8, paddingRight: 8, paddingTop: 8, paddingBottom: 8, backgroundColor: '#f5f5f5'}}>
                <Alert variant={'secondary'} style={{color: '#888'}}>
                  {T('page.person.relation.tip')}
                </Alert>
                <label className='col-sm-12'>{T('page.person.searchPerson')}:</label>
                <Col>
                  <ReferenceInput placeHolder={T('page.person.enterName')} type={DocumentReferenceType.Person} onSelected={this.handleRelationSelected} />
                </Col>
            </Card>
            </FormGroup>
          </Col>
        </Form.Row>

      </Form>
    );
  }

  renderIdentityTab = () => {
    return <>
      <Row style={{ paddingTop: 15 }}>
        <Col md={6}>
          <FormCard icon={Icon.User} title={T('page.person.panel.identity')}>
            {this.renderIdentityForm()}
          </FormCard>
        </Col>
        <Col md={6}>
          <FormCard icon={Icon.FolderTree} title={T('page.person.panel.category')}>
            {this.renderCategoryForm()}
          </FormCard>
          <FormCard icon={Icon.Sitemap} title={T('page.person.panel.family')}>
            {this.renderFamilyInfoForm()}
          </FormCard>
        </Col>
      </Row>
      {this.renderSaveButtons()}
    </>;
  }

  handleClickedEditEvent = (event: PersonEvent) => {
    this.showModal<PersonEvent>(props => (
      <EditEventModal
        event={event}
        {...props}
      />
    )).then(event => {
      api.updateEvent(event).then(savedEvent => {
        const { events } = this.state;
        const newEvents = events.map(e => e._id === event._id ? savedEvent : e);
        newEvents.sort(personEventSorter);
        this.setState({ events: newEvents });
      });
    }).catch(() => {}); // ignore cancel
  }

  handleClickedRemoveEvent = (event: PersonEvent) => {
    this.showModal(props => (
      <ConfirmationModal
        title={T('modal.removeEvent.title')}
        message={T('modal.removeEvent.message')}
        warning={T('generic.cannotUndo')}
        acceptStyle='danger'
        acceptText={T('generic.action.remove')}
        rejectText={T('generic.action.cancel')}
        {...props}
      />
    )).then(confirmation => {
      if (!confirmation)
        return;

      api.removeEvent(event._id).then(() => {
        const { events } = this.state;
        const newEvents = events.filter(e => e._id !== event._id);
        this.setState({ events: newEvents });
      });
    });
  }

  handleClickedAddEvent = (type: PersonEventType) => {
    const { id } = this.props;
    if (!id)
      return;

    const event = createNewEvent(id, type);
    this.showModal<PersonEvent>(props => (
      <EditEventModal event={event} {...props} />
    )).then(event => {
      api.createEvent(event)
        .then(result => {
          return api.getEvent(result._id, [PersonEventLoadOption.Place, PersonQueryOptions.Memorial])
        })
        .then(result => {
        const { events } = this.state;
        const newEvents = [...events, result];
        newEvents.sort(personEventSorter);
        this.setState({ events: newEvents });
      });
    }).catch(() => {}); // ignore cancel
  }

  handleDeleteDocument = (document: IPersonDocument): Promise<boolean> => {
    return this.showModal<boolean>(props => (
      <ConfirmationModal
        title={T('modal.removePersonDocument.title')}
        message={T('modal.removePersonDocument.message')}
        acceptText={T('generic.action.remove')}
        acceptStyle='danger'
        rejectText={T('generic.action.cancel')}
        {...props}
      />
    )).then(confirmed => {
      if (!confirmed)
        return false;

      return api.removePersonDocument(document._id).then(() => {
        showNotification({
          message: T('modal.removePersonDocument.removed')
        });
        const { documents } = this.state;
        const newDocuments = documents.filter(x => x._id !== document._id);
        this.setState({ documents: newDocuments });
        return true;
      });
    });
  }

  handleDeleteAttachment = (attachment: IAttachment): Promise<boolean> => {
    return this.showModal<boolean>(props => (
      <ConfirmationModal
        title={T('modal.removeAttachment.title')}
        message={T('modal.removeAttachment.message')}
        acceptText={T('generic.action.remove')}
        acceptStyle='danger'
        rejectText={T('generic.action.cancel')}
        {...props}
      />
    )).then(confirmed => {
      if (!confirmed)
        return false;

      return api.removeAttachment(attachment._id).then(() => {
        showNotification({
          message: T('modal.removeAttachment.removed')
        });
        return true;
      });
    });
  }

  handleProjectMembershipsChanged = (memberships: IPersonProjectMembership[]) => {
    this.setState({ projectMemberships: memberships });
  }

  handlePersonStorySelected = (personStory?: IPersonStory2) => {
    const via = PageID.EditPerson
    if (personStory !== undefined) {
      this.props.history.push(`/personstories/${personStory._id}/edit?via=${via}~${this.state.person?._id}`);
    } else {
      this.props.history.push(`/personstories/${this.state.person?._id}/create?via=${via}~${this.state.person?._id}`);
    }
  }

  handleSaveDocument = (document: IPersonDocument|undefined, data: IPersonDocumentFields): Promise<IValidationResult|IPersonDocument> => {
    const { id } = this.props;
    if (id)
      data.person_id = id;

    const dataWithId = Object.assign({ _id: document ? document._id : undefined }, data);
    return api.validatePersonDocument(dataWithId, document ? 'update' : 'create')
      .then((result: IValidationResult): Promise<IPersonDocument|IValidationResult> => {
        if (result.status === 'NOK')
          return Promise.resolve(result);

        if (document) {
          return api.updatePersonDocument(dataWithId as IPersonDocumentFields & { _id: string })
            .then(x => {
              const { documents } = this.state;
              x.attachments = document.attachments;
              const newDocuments = documents.map(d => d._id === document._id ? x : d);
              this.setState({ documents: newDocuments });
              return x;
            });
        } else {
          return api.createPersonDocument(data)
            .then(x => {
              const { documents } = this.state;
              const newDocuments = [...documents, x];
              this.setState({ documents: newDocuments });
              return x;
            });
        }
      });
  }

  renderSaveButtons() {
    return (
      <BottomButtonRow>
        <Button variant='primary' onClick={this.handleClickedSave}>
          {T('generic.action.save')}
        </Button>
      </BottomButtonRow>
    );
  }

  renderEventsTab = () => {
    if (!this.state.person) {
      return (<>
        {this.renderSaveFirstRow(T("page.person.saveFirstBeforeAddingEvents"))}
      </>)
    } else {
      return (
        <PersonEventsTab
          events={this.state.events}
          onClickedEdit={this.handleClickedEditEvent}
          onClickedRemove={this.handleClickedRemoveEvent}
          onClickedAdd={this.handleClickedAddEvent}
        />
      )
    }
  };

  renderRelationsTab = () => {
    return <>
      <Row style={{ marginTop: 15, marginBottom: '2.5em' }}>
        <Col md={12}>
          <FormCard icon={Icon.Link} title={T('page.person.panel.relations')}>
            {this.renderRelationsForm()}
          </FormCard>
        </Col>
      </Row>
      {this.renderSaveButtons()}
    </>;
  }

  renderSaveFirstRow = (text: string) => {
    return (
      <EmptyMessage message={text}/>
    )
  }


  renderDocumentsTab = () => {
    if (!this.state.person) {
      return (<>
        {this.renderSaveFirstRow(T("page.person.saveFirstBeforeAddingEvents"))}
      </>)
    } else {
      const personObject = new Person(this.state.person)
      return (
        <ListDocumentsTab
          ref_id={personObject.id}
          ref_name={personObject.name}
          ref_type='person'
          history={this.props.history}
          loading={this.props.loading}
          modals={this.props.modals}
          // pending={this.props.pending}
          via={getPageVia(PageID.EditPerson, personObject.id)}
          readonly={false}
          pageStateKey='editPlaceDocumentation'
        />
      );
    }
  }

  renderProjectsTab = () => {
    const { projects, projectMemberships } = this.state;
    return (
      <div style={{ marginBottom: '2.5em' }}>
        <PersonProjectsTab
          memberships={projectMemberships}
          projects={projects}
          onMembershipsChanged={this.handleProjectMembershipsChanged}
        />
        {this.renderSaveButtons()}
      </div>
    );
  }

  renderPersonStoriesTab = () => {
    return (
      <div style={{ marginBottom: '2.5em' }}>
        <PersonStoriesTab
          personStories={this.state.personStories}
          onPersonStorySelected={this.handlePersonStorySelected}
        />
        {this.renderSaveButtons()}
      </div>
    );
  }

  renderContent() {
    return (
      <TopTabs
        id='edit-person-tabs'
        defaultActiveKey='identity'
        tabs={this.generateTabs()}
        storeKey='editPerson'
      />
    );
  }
}

const mapState = (state: AppState): EditPersonStateProps => ({
  contactMoment: state.activeContactMoment.current
});
const mapDispatch = (dispatch: Dispatch<AnyAppAction>): EditPersonDispatchProps => ({
  registerContribution: (contribution: ContactMomentContribution) =>
    dispatch(registerContactMomentContributionAction(contribution))
});
const ConnectedPage = connect(mapState, mapDispatch)(EditPersonPage);
export default ConnectedPage;
