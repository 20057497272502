import React, { useMemo } from 'react';
import { Icon } from '../../components/Icons';
import { usePerson, usePublishedPersonStory } from '../../FunctionalAPI';
import { PersonQueryOptions } from '../../models/Person';
import { personEventSorter } from '../../models/PersonEvent';
import { getActiveLanguage, T } from '../../Translate';
import { PageID } from '../../utils/PageLinking';
import { PageProps, usePageHeader } from '../Page';
import styles from './index.module.scss';
import { PublicLink } from './PublicButton';
import { StoryView } from './StoryView';
import { TimelineEventView } from './TimelineEventView';
import { Col, Row } from "react-bootstrap";

interface ViewPersonStoryPageProps extends PageProps {
  id: string;
  child: boolean;
  variant: 'museum'|'key';
}

const namelistLinks: {[key: string]: string} = {
  'nl': 'https://www.inflandersfields.be/nl/kenniscentrum/namenlijst/',
  'en': 'https://www.inflandersfields.be/en/kenniscentrum-e/namenlijst-e',
  'fr': 'https://www.inflandersfields.be/fr/kenniscentrum-f/namenlijst-f',
  'de': 'https://www.inflandersfields.be/de/kenniscentrum-d/namenlijst-d'
};

const PersonWithEvents: PersonQueryOptions[] = [PersonQueryOptions.EventsWithPlaces];
export const ViewPersonStoryPage = (props: ViewPersonStoryPageProps) => {
  const { id, loading, child, variant } = props;

  const story = usePublishedPersonStory(loading, id);
  const person = usePerson(loading, story && story.person_id_ref, PersonWithEvents, 'GUEST');
  const firstName = person ? person.firstName : '';
  const lastName = person ? person.lastName : '';

  usePageHeader(
    PageID.PublicViewPersonStory,
    id,
    Icon.Search,
    firstName,
    firstName,
    undefined,
    undefined,
    undefined,
    lastName
  );

  const alsoKnownAs = useMemo(() => {
    if (!person)
      return '';

    const alternativeFirstNames = person.alternativeFirstNames;
    const alternativeLastNames = person.alternativeLastNames;

    if (alternativeFirstNames.length > 0 && alternativeLastNames.length > 0) {
      return T('page.person.alsoKnownAs', {
        firstName: alternativeFirstNames.join('/'),
        lastName: alternativeLastNames.join('/')
      });
    } else if (alternativeFirstNames.length > 0) {
      return T('page.person.alsoKnownAs', {
        firstName: alternativeFirstNames.join('/'),
        lastName
      });
    } else if (alternativeLastNames.length > 0) {
      return T('page.person.alsoKnownAs', {
        firstName,
        lastName: alternativeLastNames.join('/')
      });
    } else {
      return "";
    }
  }, [person, firstName, lastName]);

  const eventViews = useMemo(() => {
    if (!person)
      return <div />;

    person.events.sort(personEventSorter);
    return person.events.map(event => <TimelineEventView key={event._id} event={event} />);
  }, [person]);

  return <>
    <div className={styles.header}>
      <div className='container'>
        <Row>
          <Col md={7} style={{ fontSize: 16 }}>
            <div style={{ marginLeft: 16, marginRight: 16 }}>
              {alsoKnownAs && <p>{alsoKnownAs}</p>}
              {person?.familyInfo}
            </div>
          </Col>
          <Col md={5}>
            <div style={{ float: 'right', marginTop: '0.75em' }}>
              <PublicLink url={namelistLinks[getActiveLanguage()]}>
                {T('menu.aboutNamelist')}
              </PublicLink>
              <PublicLink style={{ marginLeft: 10 }} url={`/publicsearch/#/search/language=${getActiveLanguage()}`}>
                <i className={Icon.Search} />
              </PublicLink>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    {story && <StoryView story={story} child={child} />}
    <div className='container'>
      <div className={styles.timeline}>
        {eventViews}
      </div>
    </div>
  </>;
}
