import { useState, useEffect } from "react";
import { Loading } from "./utils/Loading";
import api from "./api";
import { singular, plural } from "./Translate";
import { DocumentCollection, DocumentCollectionLoadOption, IDocumentCollection } from "./models/Collection";
import { IDocument } from "./models/Document";
import { IndexedList } from "./models/IndexedList";
import { DocumentTagDefinition } from "./models/DocumentTagDefinition";
import { IContactPerson, ContactPersonEvent } from "./models/ContactPerson";
import { PersonQueryOptions, Person } from "./models/Person";
import { IPlace } from "./models/Place";
import { IMilitaryEntity } from "./models/MilitaryEntity";
import { ISpecialFunction } from "./models/SpecialFunction";
import { IProject } from "./models/Project";
import { IMemorial, GetMemorialOption } from "./models/Memorial";
import { IPersonStory2, IPublishedPersonStory } from "./models/PersonStory2";

type FunctionalFetchResult<T> = [T, () => void, (value: T) => void];

export function useDocumentCollection(
  loading: Loading,
  id: string
): FunctionalFetchResult<DocumentCollection> {
  const [result, setResult] = useState(DocumentCollection.empty());
  const refresh = () => {
    loading.loading(api.getDocumentCollection(id, [DocumentCollectionLoadOption.Person]), singular('collection'))
      .then(setResult);
  };
  useEffect(refresh, [loading, id]);
  return [result, refresh, setResult];
}

export function useOptionalDocumentCollection(
  loading: Loading,
  id?: string
): FunctionalFetchResult<DocumentCollection|undefined> {
  const [result, setResult] = useState<DocumentCollection|undefined>();
  const refresh = () => {
    if (!id) {
      setResult(undefined);
      return;
    }

    loading.loading(api.getDocumentCollection(id), singular('collection'))
      .then(setResult);
  };
  useEffect(refresh, [loading, id]);
  return [result, refresh, setResult];
}


export function useCollectionDocuments(
  loading: Loading,
  collectionId: string,
  includeRemoved: boolean
): FunctionalFetchResult<IDocument[]> {
  const [result, setResult] = useState<IDocument[]>([]);
  const refresh = () => {
    loading.loading(
      api.getCollectionDocuments(collectionId, includeRemoved),
      plural('document')
    )
      .then(setResult);
  };
  useEffect(refresh, [loading, collectionId, includeRemoved]);
  return [result, refresh, setResult];
}

export function useDocumentTagDefinitions(loading?: Loading) {
  const [result, setResult] = useState<IndexedList<DocumentTagDefinition>>(IndexedList.empty());
  const deps = loading ? [loading] : []
  useEffect(() => {
    if (loading) {
      loading.loading(api.getCollectionDocumentTagsIndexed(), plural('documentTag'))
        .then(setResult);
    } else {
      api.getCollectionDocumentTagsIndexed()
        .then(setResult);
    }
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
  return result;
}

export function useDocument(loading: Loading, id?: string): FunctionalFetchResult<IDocument|undefined> {
  const [result, setResult] = useState<IDocument|undefined>();
  const refresh = () => {
    if (!id) {
      setResult(undefined);
      return;
    }

    loading.loading(api.getCollectionDocument(id), singular('document'))
      .then(setResult);
  };
  useEffect(refresh, [loading, id]);
  return [result, refresh, setResult];
}

export function useContactPerson(loading: Loading, id?: string): IContactPerson|undefined {
  const [result, setResult] = useState<IContactPerson|undefined>();
  const refresh = () => {
    if (!id) {
      setResult(undefined);
      return;
    }

    loading.loading(api.getContactPerson(id, ['EXTEND_PLACE']), singular('contactPerson'))
      .then(setResult);
  };
  useEffect(refresh, [loading, id]);
  return result;
}

export function useContactPersonEvents(
  loading: Loading,
  contactPersonId?: string
): [ContactPersonEvent[], () => void, (events: ContactPersonEvent[]) => void] {
  const [result, setResult] = useState<ContactPersonEvent[]>([]);
  const refresh = () => {
    if (!contactPersonId) {
      setResult([]);
      return;
    }

    loading.loading(api.getContactPersonEventsForPerson(contactPersonId), plural('contactPersonEvent'))
      .then(events => {
        events.sort((a, b) => b.start.localeCompare(a.start));
        setResult(events);
      });
  }
  useEffect(refresh, [loading, contactPersonId]);
  return [result, refresh, setResult];
}

export function useContactPersonEventsForDocument(
  loading: Loading,
  documentId?: string
): [ContactPersonEvent[], () => void, (events: ContactPersonEvent[]) => void] {
  const [result, setResult] = useState<ContactPersonEvent[]>([]);
  const refresh = () => {
    if (!documentId) {
      setResult([]);
      return;
    }

    loading.loading(api.findContactMomentsForDocument(documentId), plural('contactPersonEvent'))
      .then(setResult);
  }
  useEffect(refresh, [loading, documentId]);
  return [result, refresh, setResult];
}

export function usePerson(
  loading: Loading,
  id?: string,
  options: PersonQueryOptions[] = [PersonQueryOptions.EventsWithPlaces, PersonQueryOptions.Documents, PersonQueryOptions.RelationPersons],
  token?: string
): Person|undefined {
  const [result, setResult] = useState<Person|undefined>();
  const refresh = () => {
    if (!id) {
      setResult(undefined);
    } else {
      loading.loading(api.getPerson(id, options, token), singular('person'))
        .then(person => setResult(new Person(person)));
    }
  };
  useEffect(refresh, [loading, id]);
  return result;
}

export function usePersonContactMoments(loading: Loading, personId: string|undefined): ContactPersonEvent[] {
  const [result, setResult] = useState<ContactPersonEvent[]>([]);
  const refresh = () => {
    if (!personId) {
      setResult([]);
      return;
    }

    loading.loading(api.findContactMomentsForPerson(personId), plural('contactMoment'))
      .then(setResult);
  };
  useEffect(refresh, [loading, personId]);
  return result;
}

export function usePersonStories(loading: Loading, personId: string|undefined): IPersonStory2[] {
  const [result, setResult] = useState<IPersonStory2[]>([]);
  const refresh = () => {
    if (!personId) {
      setResult([]);
      return;
    }
    loading.loading(api.findPersonStoriesForPerson(personId), plural('personStory'))
      .then(queryResult => {
        setResult(queryResult.data)
      });
  };
  useEffect(refresh, [loading, personId]);
  return result;
}

export function usePersonStory(loading: Loading, storyId: string|undefined): IPersonStory2|undefined {
  const [result, setResult] = useState<IPersonStory2>();
  const refresh = () => {
    if (!storyId) {
      setResult(undefined);
      return;
    }
    loading.loading(api.getPersonStory2(storyId), plural('personStory'))
      .then(setResult);
  };
  useEffect(refresh, [loading, storyId]);
  return result;
}

export function usePublishedPersonStory(loading: Loading, storyId: string|undefined): IPublishedPersonStory|undefined {
  const [result, setResult] = useState<IPublishedPersonStory>();
  const refresh = () => {
    if (!storyId) {
      setResult(undefined);
      return;
    }
    loading.loading(api.getPublishedPersonStory(storyId), plural('personStory'))
      .then(setResult);
  };
  useEffect(refresh, [loading, storyId]);
  return result;
}

export function usePersonDocumentCollections(loading: Loading, personId: string) {
  const [result, setResult] = useState<IDocumentCollection[]>([]);
  useEffect(() => {
    loading
      .loading(api.getDocumentCollectionsForPerson(personId), plural('collection'))
      .then(setResult);
  }, [loading, personId]);
  return result;
}

export function usePersonRelatedDocuments(loading: Loading, personId: string) {
  const [result, setResult] = useState<IDocument[]>([]);
  useEffect(() => {
    loading.loading(api.getDocumentsRelatedTo('person', personId), plural('document'))
      .then(setResult);
  }, [loading, personId]);
  return result;
}

export function usePlace(loading: Loading, id?: string): IPlace|undefined {
  const [result, setResult] = useState<IPlace>();
  useEffect(() => {
    if (!id) {
      setResult(undefined);
      return;
    }

    loading.loading(api.getPlace(id), singular('place'))
      .then(setResult);
  }, [loading, id]);
  return result;
}

export function useMemorial(loading : Loading, id?: string, options?: GetMemorialOption[]): IMemorial|undefined {
  const [result, setResult] = useState<IMemorial>();
  useEffect(() => {
    if (!id) {
      setResult(undefined);
      return;
    }

    loading.loading(api.getMemorial(id, options), singular('memorial'))
      .then(setResult);
  }, [loading, id, options]);
  return result;
}

const NoOptions: [] = [];
export function useMilitaryEntity(
  loading: Loading,
  id?: string,
  options: ('history.place'|'history.entity')[] = NoOptions
): IMilitaryEntity|undefined {
  const [result, setResult] = useState<IMilitaryEntity>();
  useEffect(() => {
    if (!id) {
      setResult(undefined);
      return;
    }

    loading.loading(api.getMilitaryEntity(id, options), singular('militaryEntity'))
      .then(setResult);
  }, [loading, id, options]);
  return result;
}

export function useFunctionDefinition(id: string): ISpecialFunction|undefined {
  const [result, setResult] = useState<ISpecialFunction>();
  useEffect(() => {
    api.getFunction(id).then(setResult);
  }, [id]);
  return result;
}

export function useProject(loading: Loading, id: string) {
  const [result, setResult] = useState<IProject>();
  useEffect(
    () => { loading.loading(api.getProject(id), 'project').then(setResult) },
    [loading, id]
  );
  return result;
}

export function useProjects(loading: Loading) {
  const [result, setResult] = useState<IProject[]>([]);
  useEffect(
    () => {
      loading.loading(api.findProjects(undefined, undefined, undefined, []), plural('project'))
        .then(projects => setResult(projects.data));
    },
    [loading]
  );
  return result;
}

export function useNumberOfRelatedDocuments(ref_type: 'person'|'place'|'memorial'|'military_entity', ref_id: string | undefined) {
  const [numberOfDocuments, setNumberOfDocuments] = useState<number>(0)
  useEffect(() => {
      if (ref_id) {
        api.countDocumentsRelatedTo(ref_type, ref_id).then(setNumberOfDocuments);
      } else {
        setNumberOfDocuments(0)
      }
    },
    [ref_id, ref_type]
  );
  return numberOfDocuments
}
