import { combineReducers } from 'redux';
import { AppActionType, AnyAppAction } from './Actions';
import { IDocumentEditorSettings, PagesState, ActivePageState, ISharedPageState, TabState, IExportModalState } from './State';
import { Dataset } from '../models/User';
import { ResolutionStatus } from '../models/Place';
import { ListMilitaryEntityKind } from '../pages/ListMilitaryEntities';
import { ViewCollectionDefaultState } from '../pages/ViewCollection';
import { ContactMomentState } from '../models/ActiveContactMoment';
import { SortDirection } from '../components/table/Table';
import { Icon } from '../components/Icons';
import { PageLayout } from '../pages/Page';

function documentEditorSettings(state: IDocumentEditorSettings, action: AnyAppAction) {
    switch (action.type) {
        case AppActionType.DocumentEditorSetRatio:
            return Object.assign({}, state, {ratio: action.payload.ratio});
        default:
            return state || {ratio: +(localStorage.getItem('documentEditorRatio') || 0.5)};
    }
}

const DefaultPagesState: PagesState = {
  advancedSearch: {
    args: {},
    errors: {},
    result: undefined,
    tab: 'parameters'
  },
  collection: ViewCollectionDefaultState,
  collections: {
    filter: '',
    tablePage: 0,
    sortColumn: 'title',
    sortDirection: SortDirection.Up,
    filters: [],
    showRemoved: false
  },
  persons: {
    filter: '',
    tablePage: 0,
    sortColumn: 'filter',
    sortDirection: SortDirection.Up,
    filters: [],
    dataset: Dataset.Namelist
  },
  memorials: {
    filter: '',
    tablePage: 0,
    sortColumn: 'filter',
    sortDirection: SortDirection.Up,
    filters: []
  },
  personStories: {
    filter: '',
    tablePage: 0,
    sortColumn: 'calculated_full_name',
    sortDirection: SortDirection.Up,
    filters: []
  },
  militaryEntities: {
    filter: '',
    tablePage: 0,
    sortColumn: 'name',
    sortDirection: SortDirection.Up,
    kind: ListMilitaryEntityKind.RegimentOrUnit,
    filters: []
  },
  places: {
    filter: '',
    tablePage: 0,
    sortColumn: 'name',
    sortDirection: SortDirection.Up,
    filterStatus: ResolutionStatus.All,
    filters: []
  },
  project: {
    filter: '',
    tablePage: 0,
    sortColumn: 'filter',
    sortDirection: SortDirection.Up,
    filters: []
  },
  projects: {
    filter: '',
    tablePage: 0,
    sortColumn: 'title',
    sortDirection: SortDirection.Up,
    filters: []
  },
  users: {
    filter: '',
    tablePage: 0,
    sortColumn: 'account',
    sortDirection: SortDirection.Up,
    filters: []
  },
  contactPersons: {
    filter: '',
    tablePage: 0,
    sortColumn: 'name',
    sortDirection: SortDirection.Up,
    filters: []
  },
  contactPersonEvents: {
    filter: '',
    tablePage: 0,
    sortColumn: 'name',
    sortDirection: SortDirection.Up,
    filters: []
  },
  documentation: {
    filter: '',
    tablePage: 0,
    sortColumn: 'title',
    sortDirection: SortDirection.Up,
    tagFilter: null,
    filters: []
  },
  militaryEntityPersons: {
    filter: '',
    tablePage: 0,
    sortColumn: 'name',
    sortDirection: SortDirection.Up,
    filters: []
  },
  memorialPersons: {
    filter: '',
    tablePage: 0,
    sortColumn: 'name',
    sortDirection: SortDirection.Up,
    filters: []
  },
  personStoryMatches: {
    filter: '',
    tablePage: 0,
    sortColumn: 'name',
    sortDirection: SortDirection.Up,
    filters: []
  },
  placeDocumentation: {
    filter: '',
    tablePage: 0,
    sortColumn: 'title',
    sortDirection: SortDirection.Up,
    tagFilter: null,
    filters: []
  },
  editPlaceDocumentation: {
    filter: '',
    tablePage: 0,
    sortColumn: 'title',
    sortDirection: SortDirection.Up,
    tagFilter: null,
    filters: []
  },
  militaryDocumentation: {
    filter: '',
    tablePage: 0,
    sortColumn: 'title',
    sortDirection: SortDirection.Up,
    tagFilter: null,
    filters: []
  },
  editMilitaryDocumentation: {
    filter: '',
    tablePage: 0,
    sortColumn: 'title',
    sortDirection: SortDirection.Up,
    tagFilter: null,
    filters: []
  },
  editPersonDocumentation: {
    filter: '',
    tablePage: 0,
    sortColumn: 'title',
    sortDirection: SortDirection.Up,
    tagFilter: null,
    filters: []
  },
  memorialDocumentation: {
    filter: '',
    tablePage: 0,
    sortColumn: 'title',
    sortDirection: SortDirection.Up,
    tagFilter: null,
    filters: []
  },
  editMemorialDocumentation: {
    filter: '',
    tablePage: 0,
    sortColumn: 'title',
    sortDirection: SortDirection.Up,
    tagFilter: null,
    filters: []
  },
  publicSearch: {
    selectedTab: 'basic',
    basicInput: {},
    advancedInput: {},
    filter: '',
    tablePage: 0,
    sortColumn: 'name',
    sortDirection: SortDirection.Up,
    filters: []
  }
};

function pagesState(state: PagesState, action: AnyAppAction): PagesState {
  switch (action.type) {
    case AppActionType.UpdatePagesState:
      const index = action.payload.page as keyof PagesState
      const value = Object.assign({}, state[index], action.payload.value);
      return Object.assign({}, state, { [action.payload.page]: value });
    default:
      return state || DefaultPagesState;
  }
}

const DefaultTabState = {
  editPlace: '',
  viewPlace: '',
  editMemorial: '',
  viewMemorial: '',
  editPerson: '',
  viewPerson: '',
  editMilitary: '',
  viewMilitary: '',
  editPersonStory: '',
}

function tabsState(state: TabState, action: AnyAppAction): TabState {
  switch (action.type) {
    case AppActionType.UpdateTabState:
      const value = action.payload.value;
      return Object.assign({}, state, { [action.payload.page]: value });
    default:
      return state || DefaultTabState;
  }
}

function activeContactMoment(state: ContactMomentState, action: AnyAppAction) {
  switch (action.type) {
    case AppActionType.StartContactMoment:
      localStorage.setItem('activeContactMoment', JSON.stringify(action.payload));
      return { current: action.payload };
    case AppActionType.UpdateContactMoment: {
      if (!state.current)
        return state;

      const value = Object.assign({}, state.current, action.payload);
      localStorage.setItem('activeContactMoment', JSON.stringify(value));
      return { current: value };
    }
    case AppActionType.RegisterContactMomentContribution: {
      if (!state.current)
        return state;

      const updates = { contributions: [...state.current.contributions, action.payload] };
      const value = Object.assign({}, state.current, updates);
      localStorage.setItem('activeContactMoment', JSON.stringify(value));
      return { current: value };
    }
    case AppActionType.EndContactMoment:
      localStorage.removeItem('activeContactMoment');
      return { current: undefined };
    default:
      const stored = localStorage.getItem('activeContactMoment');
      return state || (stored && { current: JSON.parse(stored) }) || {};
  }
}

function activePageState(state: ActivePageState, action: AnyAppAction): ActivePageState {
  switch (action.type) {
    case AppActionType.SetActivePage:
      return action.payload;
    default:
      return state || { title: '', subtitle: '', icon: Icon.Page, layout: PageLayout.Regular, breadcrumbs: [] };
  }
}

function sharedPageState(state: ISharedPageState, action: AnyAppAction): ISharedPageState {
  switch (action.type) {
    case AppActionType.SetSharedPageState:
      return action.payload;
    default:
      return state || {};
  }
}

function exportModalState(state: IExportModalState, action: AnyAppAction): IExportModalState {
  switch (action.type) {
    case AppActionType.UpdateExportModalState:
      return Object.assign({}, state, action.payload.updates);
    default:
      return state || {};
  }
}

const appReducers = combineReducers({
    documentEditorSettings,
    pagesState,
    tabsState,
    activeContactMoment,
    activePageState,
    sharedPageState,
    exportModalState
});
export default appReducers;
