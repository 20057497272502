import React from "react";
import { Icon } from "../../components/Icons";
import { ActionsTableColumn, ComponentTableColumn, ITableColumn, StringFieldTableColumn, StringTableColumn, tableRowActions, TableRowActionStyle } from "../../components/table/Fields";
import { IPersonStory2, Language } from "../../models/PersonStory2";
import { T } from "../../Translate";
import { PersonStoryChildLanguageStatusView, PersonStoryLanguageStatusView } from "./PersonStoryStatusView";

export function getPersonStoryColumns(canEdit: boolean, onClickedDelete: (story: IPersonStory2) => void) {
  const columns: ITableColumn<IPersonStory2>[] = [
    new StringFieldTableColumn('calculated_full_name', T('personstories.name'), { ellipsize: true, width: 200 }),
  ];

  for (const language of Object.values(Language)) {
    columns.push(new ComponentTableColumn(`story_text.${language}.story_status`, T('personstories.storyStatusForLanguage', {language: language}), personStory => (
      <PersonStoryLanguageStatusView personStory={personStory} language={language as Language} />
    ), { sortable: false, width: 78, filter: [
        { key: 'in_progress', value: 'in progress', label: T('personstories.status.inprogress') },
        { key: 'in_review', value: 'in review', label: T('personstories.status.inreview') },
        { key: 'done', value: 'done', label: T('personstories.status.done') }
      ]
    }));
  }
  for (const language of Object.values(Language)) {
    columns.push(new ComponentTableColumn(`story_text.${language}.story_child_status`, T('personstories.storyChildStatusForLanguage', {language: language}), personStory => (
      <PersonStoryChildLanguageStatusView personStory={personStory} language={language as Language} />
    ), { sortable: false, width: 78, filter: [
        { key: 'in_progress', value: 'in progress', label: T('personstories.status.inprogress') },
        { key: 'in_review', value: 'in review', label: T('personstories.status.inreview') },
        { key: 'done', value: 'done', label: T('personstories.status.done') }
      ]
    }));
  }

  columns.push(new ComponentTableColumn('keyStory', T('page.personstory.tab.keystory'), personStory => (
    personStory.key_position ? <i className="far fa-check" /> : <i className="far fa-ban" />
  ), { sortable: false, icon: 'far fa-key-skeleton', width: 30 }));

  columns.push(new ComponentTableColumn('photo_ok', T('personStories.storyStatusPhoto'), personStory => (
    personStory.profile_image ? <i className="far fa-check" /> : <i className="far fa-ban" />
  ), { sortable: false, icon: 'far fa-id-badge', width: 30 }));

  columns.push(new ComponentTableColumn('album', T('personStories.storyAlbum'), personStory => (
    personStory.extra_images?.length ? personStory.extra_images?.length : <i className="far fa-ban" />
  ), { icon: 'fas fa-images', width: 30 }));

/*  columns.push(new ComponentTableColumn('links', T('personStories.storyLinks'), personStory => (
    personStory.coupling_story_refs?.length ? personStory.coupling_story_refs.length : <i className="far fa-ban" />
  )));*/

  columns.push(new StringTableColumn('published_date', T('personstories.published.date'), personStory => (
    personStory.published_date ? new Date(personStory.published_date).toLocaleDateString() : T('personstories.notPublished')
  ), { width: 125 }));

  if (canEdit) {
    columns.push(new ActionsTableColumn('actions', T('generic.list.actions'), () => tableRowActions(
      { icon: Icon.TimesCircle, style: TableRowActionStyle.Danger, onClick: onClickedDelete }
    ), { width: 70 }));
  }
  return columns;
}
