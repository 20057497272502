import React, { CSSProperties } from 'react';

interface IconProps {
  icon: Icon;
  style?: CSSProperties;
}
export const IconElement = (props: IconProps) => <i className={props.icon} style={props.style} />;
export const LoadingIcon = () => <i className="fas fa-circle-o-notch fa-spin" />;

export enum Icon {
  Page = 'fa fa-file',
  Home = 'far fa-home',
  Box = 'fas fa-box',
  Documentation = 'fas fa-file-invoice',
  Users = 'fas fa-users',
  Search = 'fas fa-search',
  QuoteRight = 'fas fa-quote-right',
  Monument = 'fas fa-monument',
  MapMarker = 'fas fa-map-marker-alt',
  Sitemap = 'fas fa-sitemap',
  Cogs = 'fas fa-cogs',
  Database = 'fas fa-database',
  Books = 'fas fa-books',
  Sort = 'fas fa-sort',
  SortUp = 'fas fa-sort-up',
  SortDown = 'fas fa-sort-down',
  ListUl = 'fal fa-list-ul',
  Tools = 'fas fa-tools',
  SignOut = 'fas fa-sign-out-alt',
  Times = 'fas fa-times',
  TimesCircle = 'fas fa-times-circle',
  SearchMinus = 'far fa-search-minus',
  SearchPlus = 'far fa-search-plus',
  AngleLeft = 'far fa-angle-left',
  AngleRight = 'far fa-angle-right',
  AngleLeftLight = 'fal fa-angle-left',
  AngleRightLight = 'fal fa-angle-right',
  ChevronLeftLight = 'fal fa-chevron-left',
  ChevronRightLight = 'fal fa-chevron-right',
  User = 'fas fa-user',
  Book = 'fas fa-book',
  Question = 'fas fa-question',
  Edit = 'fas fa-edit',
  ChevronLeft = 'fas fa-chevron-left',
  ChevronRight = 'fas fa-chevron-right',
  ChevronDoubleLeft = 'fas fa-chevron-double-left',
  ChevronDoubleRight = 'fas fa-chevron-double-right',
  Info = 'far fa-info-circle',
  PlusSquare = 'fas fa-plus-square',
  Images = 'fas fa-images',
  Merge = 'fas fa-compress-alt',
  FolderTree = 'fas fa-folder-tree',
  Copy = 'fas fa-copy',
  Link = 'far fa-link',
  Cross = 'far fa-cross',
  Circle = 'far fa-circle',
  House = 'far fa-house',
  Comment = 'far fa-comment',
  GraduationCap = 'far fa-graduation-cap',
  Shield = 'far fa-shield',
  Medkit = 'far fa-medkit',
  Ambulance = 'far fa-ambulance',
  RingsWedding = 'far fa-rings-wedding',
  Medal = 'far fa-medal',
  Tag = 'far fa-tag',
  Loading = 'fa fa-spin fa-circle-notch',
  Paperclip = 'far fa-paperclip',
  ExternalLink = 'far fa-external-link',
  Comments = 'far fa-comments',
  Document = 'fas fa-file',
  Eye = 'fas fa-eye',
  Plus = 'far fa-plus',
  Route = 'far fa-route',
  Gear = 'far fa-cog',
  Gears = 'far fa-cogs',
  Check = 'far fa-check',
  CheckSquare = 'far fa-check-square',
  CheckCircle = 'far fa-check-circle',
  Pencil = 'far fa-pencil',
  Square = 'far fa-square',
  LightSquare = 'fal fa-square',
  SolidSquare = 'fas fa-square',
  Switch = 'far fa-exchange-alt',
  Refresh = 'far fa-redo-alt',
  Tags = 'far fa-tags',
  AddressBook = 'far fa-address-book',
  Start = 'far fa-play',
  Stop = 'far fa-stop',
  Lock = 'far fa-lock',
  FileAlt = 'far fa-file-alt',
  AlignJustify = 'far fa-align-justify',
  ExpandArrowsAlt = 'far fa-expand-arrows-alt',
  FilesMedical = 'far fa-files-medical',
  PersonStory = 'far fa-book',
  Male = 'far fa-mars',
  Female = 'far fa-venus',
  Google = 'fab fa-google',
  Child = 'fas fa-child',
  Man = 'fas fa-male',
  Baby = 'fas fa-baby',
  ProfileDocument = 'fal fa-file-user',
  LockAlt = 'fas fa-lock-alt',
  UnlockAlt = 'fas fa-unlock-alt',
  Print = 'fas fa-print',
  Clock = 'far fa-clock',
  Mailbox = 'far fa-mailbox',
  Inbox = 'far fa-inbox',
  ContactMoment = 'far fa-comments-alt',
  Key = 'far fa-key-skeleton',
  Filter = 'fas fa-filter'
}
