import { useCallback, useEffect, useMemo, useState } from "react";
import { History } from 'history';
import { Badge } from 'react-bootstrap';
import { SortDirection, sortItems, Table } from "../../components/table/Table";
import { ISpecialFunctionTableResult, SpecialFunctionTableItem } from "../../models/SpecialFunction";
import { ComponentTableColumn, ITableColumn, StringTableColumn } from "../../components/table/Fields";
import { T } from "../../Translate";
import styles from './index.module.scss';
import { DateColumn } from "../SpecialFunctions/SpecialFunctionResultTable";
import { IPersonMilitaryInfo, translateVictimType, VictimType } from "../../models/Person";
import api from "../../api";
import { PublicSearchState } from ".";

interface PublicSearchPersonsTableProps {
  state: PublicSearchState;
  updateState: (updates: Partial<PublicSearchState>) => void;
  history: History;
}

function getPublicColumns(): ITableColumn<SpecialFunctionTableItem>[] {
  return [
    new ComponentTableColumn('name', T('listPersons.column.name'), person => {
      const altFirstNames = person.firstNameAlt ? ' (' + person.firstNameAlt + ')' : '';
      const altLastNames = person.lastNameAlt ? ' (' + person.lastNameAlt + ')' : '';
      return (
        <span>
          <strong className={styles.ellipsized}>{person.familyName}{altLastNames}</strong>
          <br />
          <span className={styles.ellipsized}>{person.firstName}{altFirstNames}</span>
        </span>
      );
    }, { sortable: true, clickable: true }, (a, b) => {
      const aName = a.familyName as string + a.firstName as string;
      const bName = b.familyName as string + b.firstName as string;
      return aName.localeCompare(bName);
    }),
    new DateColumn('bornDate', T('listPersons.column.birthDate'), { width: 110 }),
    new DateColumn('diedDate', T('listPersons.column.diedDate'), { width: 110 }),
    new StringTableColumn('type', T('listPersons.column.type'), person => translateVictimType(person.victimType as VictimType), { width: 110 }),
    new StringTableColumn('army', T('listPersons.column.army'), person => person.army as string, { width: 110, sortable: false }),
    new ComponentTableColumn('details', T('listPersons.column.details'), person => {
      if (person.victimType === VictimType.Military) {
        let name = person.regiment as string;
        if (person.unit)
          name += ', ' + person.unit;
        if (person.unit_nr)
          name += ', ' + person.unit_nr;
        if (person.sub)
          name += ', ' + person.sub;
        
        return (
          <>
            <Badge pill variant='secondary'>{person.number}</Badge>
            {' '}
            {person.rank}
            <br />
            {name && <span title={name}>{name}</span>}
          </>
        );
      } else {
        return <span />;
      }
    }, { clickable: true, sortable: false, className: styles.militaryInfoColumn })
  ];
}

export default function PublicSearchPersonsTable(props: PublicSearchPersonsTableProps) {
  const { state, updateState, history } = props;
  const { tablePage: page, sortColumn, sortDirection, results } = state;
  const resultsTable = results as ISpecialFunctionTableResult;
  
  const columns = useMemo(() => getPublicColumns(), []);
  const pageSize = 20;

  const [details, setDetails] = useState<{[key: string]: IPersonMilitaryInfo}>({});

  const handleSortUpdated = (column: string, direction: SortDirection) => {
    updateState({ sortColumn: column, sortDirection: direction });
  };
  const sortedData = useMemo(
    () => sortItems(columns, resultsTable.data, sortColumn, sortDirection),
    [resultsTable, columns, sortColumn, sortDirection]
  );
  const onClickItem = useCallback((item: SpecialFunctionTableItem) => history.push('/person/_id=' + item._id), [history]);

  useEffect(() => {
    const slice = sortedData.slice(page * pageSize, (page + 1) * pageSize);
    api.getPersonsDetails(slice.map(item => item._id as string)).then(setDetails);
  }, [sortedData, page]);

  const sliceWithDetails = useMemo(() => {
    const slice = sortedData.slice(page * pageSize, (page + 1) * pageSize);
    return slice.map(person => ({...person, ...details[person._id as string]})) as SpecialFunctionTableItem[];
  }, [sortedData, details, page]);

  return (
    <Table
      className={styles.table}
      displayedItems={sliceWithDetails}
      columns={columns}
      onPageChanged={page => updateState({ tablePage: page })}
      onSortChanged={handleSortUpdated}
      onClickItem={onClickItem}
      
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      page={page}
      pageSize={10}
      totalItems={resultsTable.data.length}
      noun='person'
    />
  );
}
