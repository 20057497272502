import { Route, Switch, Redirect } from 'react-router-dom';

import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import ListCollectionsPage from './pages/ListCollections';
import CreateCollectionPage from './pages/CreateCollection';
import EditCollectionPage from './pages/EditCollection';
import ViewCollectionPage from './pages/ViewCollection';
import EditCollectionDocument from './pages/EditCollectionDocument';

import { PageLayout } from './pages/Page';
import ListMemorialsPage from './pages/ListMemorials';
import { History } from 'history';
import EditMemorialPage from './pages/EditMemorial';
import ViewMemorialPage from './pages/ViewMemorial';
import ListPersonsPage from './pages/ListPersons';
import ListPersonStoriesPage from './pages/ListPersonStories';
import EditPersonPage from './pages/EditPerson';
import { ViewPersonPage } from './pages/ViewPerson';
import ListPlacesPage from './pages/ListPlaces';
import ViewPlacePage from './pages/ViewPlace';
import EditPlacePage from './pages/EditPlace';
import ListPersonFeedback from './pages/ListPersonFeedback';
import ListUsersPage from './pages/ListUsers';
import ListProjectsPage from './pages/ListProjects';
import ViewProjectPage from './pages/ViewProject';
import ListMilitaryEntities from './pages/ListMilitaryEntities';
import ViewMilitaryEntity from './pages/ViewMilitaryEntity';
import EditMilitaryEntity from './pages/EditMilitaryEntity';
import SpecialFunctionsPage from './pages/SpecialFunctions';
import AdvancedSearchPage from './pages/AdvancedSearch';
import ActivateAccountPage from './pages/ActivateAccount';
import RequestPasswordResetPage from './pages/RequestPasswordReset';
import ListContactPersonsPage from './pages/ListContactPersons';
import ListContactMomentsPage from './pages/ListContactMoments';
import EditContactPersonPage from './pages/EditContactPerson';
import RecordContactMomentPage from './pages/RecordContactMoment';
import EditContactMomentPage from './pages/EditContactMoment';
import EditDocumentPage from './pages/EditDocumentPage';
import ListDocumentation from './pages/ListDocumentation';
import EditPersonStoryPage from "./pages/EditPersonStory";


import { ActivePageState, AppState } from './redux/State';
import { useSelector } from 'react-redux';
import { DocumentCategory } from './models/Document';
import api from "./api";
import { RouteProps } from "react-router";
import { ViewPersonStoryPage } from './pages/PublicViewPersonStory';
import { ViewStoryPairPage } from './pages/PublicViewStoryPair';
import { Dataset } from './models/User';
import { AppProps, BaseApp, Renderers } from './BaseApp';


/**
 * A component that return a `Route` Component when user is logged in, else
 * returns a `Redirect` component to the login page.
 */
const PrivateRoute = <T extends RouteProps>(props: T) =>
  api.isLoggedIn()
    ? <Route { ...props } />
    : <Redirect to="/login" />


class App extends BaseApp {
  constructor(props: AppProps) {
    super(props);
  }

  renderRouteSwitch(renderers: Renderers) {
    const { renderPage, renderWithProps } = renderers;

    return (
      <Switch>
        <PrivateRoute
          exact
          path="/"
          render={renderPage(HomePage)}
        />
        <Route
          exact
          path="/login"
          render={renderPage(LoginPage, PageLayout.NoFrames)}
        />
        <Route
          exact
          path="/activateAccount"
          render={renderWithProps(
            ActivateAccountPage,
            (props, query) => ({
              activationKey: (query.key || '') as string,
              mode: 'activate' as 'activate',
            }),
            PageLayout.Regular
          )}
        />
        <Route
          exact
          path='/requestPasswordReset'
          render={renderPage(RequestPasswordResetPage, PageLayout.NoFrames)}
        />
        <Route
          exact
          path="/resetPassword"
          render={renderWithProps(
            ActivateAccountPage,
            (props, query) => ({
              activationKey: (query.key || '') as string,
              mode: 'resetPassword' as 'resetPassword'
            }),
            PageLayout.NoFrames
          )}
        />
        <PrivateRoute
          exact
          path="/collections"
          render={renderPage(ListCollectionsPage)}
        />
        <PrivateRoute
          exact
          path="/collections/create"
          render={renderPage(CreateCollectionPage)}
        />
        <PrivateRoute
          exact
          path="/collections/:id"
          render={renderWithProps(
            ViewCollectionPage,
            props => ({ id: props.match.params.id })
          )}
        />
        <PrivateRoute
          exact
          path="/collections/:id/edit"
          render={renderWithProps(
            EditCollectionPage,
            props => ({ id: props.match.params.id })
          )}
        />
        <PrivateRoute
          exact
          path="/documents/create"
          render={renderWithProps(
            EditCollectionDocument,
            (props, query) => ({
              collection: query.collection as string|undefined,
              category: query.category as DocumentCategory|undefined,
              code: query.code as string|undefined,
              ref_id: query.ref_id as string|undefined,
              ref_type: query.ref_type as 'person'|'place'|'memorial'|'military_entity'|undefined,
              ref_name: query.ref_name as string|undefined
            })
          )}
        />
        <PrivateRoute
          exact
          path="/documents/:id"
          render={renderWithProps(
            EditCollectionDocument,
            props => ({ id: props.match.params.id })
          )}
        />
        <PrivateRoute
          exact
          path="/documents/:id/:page"
          render={renderWithProps(
            EditDocumentPage,
            props => ({
              id: props.match.params.id,
              page: parseInt(props.match.params.page)
            })
          )}
        />
        <PrivateRoute
          exact
          path='/documentation'
          render={renderPage(ListDocumentation)}
        />
        <PrivateRoute
          exact
          path='/memorials'
          render={renderPage(ListMemorialsPage)}
        />
        <PrivateRoute
          exact
          path='/memorials/create'
          render={renderPage(EditMemorialPage)}
        />
        <PrivateRoute
          exact
          path='/memorials/:id'
          render={renderWithProps(
            ViewMemorialPage,
            props => ({ id: props.match.params.id })
          )}
        />
        <PrivateRoute
          exact
          path='/memorials/:id/edit'
          render={renderWithProps(
            EditMemorialPage,
            props => ({ id: props.match.params.id })
          )}
        />
        <PrivateRoute
          exact
          path='/personStories'
          render={renderPage(ListPersonStoriesPage)}
        />
        <PrivateRoute
          exact
          path='/persons'
          render={renderWithProps<{ dataset: Dataset|undefined }>(ListPersonsPage,
            (props, query) => ({ dataset: (query.dataset || undefined) as Dataset|undefined })
          )}
        />
        <PrivateRoute
          exact
          path='/persons/create'
          render={renderPage(EditPersonPage)}
        />
        <PrivateRoute
          exact
          path='/persons/feedback'
          render={renderPage(ListPersonFeedback)}
        />
        <PrivateRoute
          exact
          path='/persons/search'
          render={renderPage(AdvancedSearchPage)}
        />
        <PrivateRoute
          exact
          path='/persons/:id/edit'
          render={renderWithProps(
            EditPersonPage,
            props => ({ id: props.match.params.id })
          )}
        />
        <Route
          exact
          path='/persons/:id'
          render={renderWithProps(
            ViewPersonPage,
            props => ({ id: props.match.params.id })
          )}
        />
        <PrivateRoute
          exact
          path='/personstories/:id/edit'
          render={renderWithProps<{id?:string, personIdRef?:string}>(
            EditPersonStoryPage,
            props => ({ id: props.match.params.id})
          )}
        />
        <PrivateRoute
          exact
          path='/personstories/:personIdRef/create'
          render={renderWithProps<{id?:string, personIdRef?:string}>(
            EditPersonStoryPage,
            props => ({ personIdRef: props.match.params.personIdRef })
          )}
        />
        <Route
          exact
          path='/public/personstories/:id'
          render={renderWithProps<{ id: string, child: boolean, variant: 'museum'|'key' }>(
            ViewPersonStoryPage,
            (props, query) => ({ id: props.match.params.id, child: query.child !== undefined, variant: 'museum' }),
            PageLayout.PublicPerson
          )}
        />
        <Route
          exact
          path='/public/remembrancekey/:id'
          render={renderWithProps<{ id: string, child: boolean, variant: 'museum'|'key' }>(
            ViewPersonStoryPage,
            (props, query) => ({ id: props.match.params.id, child: query.child !== undefined, variant: 'key' }),
            PageLayout.PublicPersonKey
          )}
        />
        <Route
          exact
          path='/public/storypair'
          render={renderWithProps<{ story1: string, story2: string, child: boolean }>(
            ViewStoryPairPage,
            (props, query) => ({
              story1: query.story1 as string || '',
              story2: query.story2 as string || '',
              child: query.child !== undefined
            }),
            PageLayout.Public
          )}
        />
        <PrivateRoute
          exact
          path='/places'
          render={renderPage(ListPlacesPage)}
        />
        <PrivateRoute
          exact
          path='/places/create'
          render={renderPage(EditPlacePage)}
        />
        <PrivateRoute
          exact
          path='/places/:id'
          render={renderWithProps(
            ViewPlacePage,
            props => ({ id: props.match.params.id })
          )}
        />
        <PrivateRoute
          exact
          path='/places/:id/edit'
          render={renderWithProps(
            EditPlacePage,
            props => ({ id: props.match.params.id })
          )}
        />
        <PrivateRoute
          exact
          path='/users'
          render={renderPage(ListUsersPage)}
        />
        <PrivateRoute
          exact
          path='/projects'
          render={renderPage(ListProjectsPage)}
        />
        <PrivateRoute
          exact
          path='/projects/:id'
          render={renderWithProps(
            ViewProjectPage,
            props => ({ id: props.match.params.id })
          )}
        />
        <PrivateRoute
          exact
          path='/military_entities'
          render={renderPage(ListMilitaryEntities)}
        />
        <PrivateRoute
          exact
          path='/military_entities/create'
          render={renderPage(EditMilitaryEntity)}
        />
        <PrivateRoute
          exact
          path='/military_entities/:id'
          render={renderWithProps(
            ViewMilitaryEntity,
            props => ({ id: props.match.params.id })
          )}
        />
        <PrivateRoute
          exact
          path='/military_entities/:id/edit'
          render={renderWithProps(
            EditMilitaryEntity,
            props => ({ id: props.match.params.id })
          )}
        />
        <PrivateRoute
          exact
          path='/functions'
          render={renderPage(SpecialFunctionsPage)}
        />
        <PrivateRoute
          exact
          path='/contact_persons'
          render={renderPage(ListContactPersonsPage)}
        />
        <PrivateRoute
          exact
          path='/contact_moments'
          render={renderPage(ListContactMomentsPage)}
        />
        <PrivateRoute
          exact
          path='/contact_persons/create'
          render={renderPage(EditContactPersonPage)}
        />
        <PrivateRoute
          exact
          path='/contact_persons/:id'
          render={renderWithProps(
            EditContactPersonPage,
            props => ({ id: props.match.params.id })
          )}
        />
        <PrivateRoute
          exact
          path='/record_contact_moment'
          render={renderPage(RecordContactMomentPage)}
        />
        <PrivateRoute
          exact
          path='/contact_moments/:id'
          render={renderWithProps(
            EditContactMomentPage,
            props => ({ id: props.match.params.id })
          )}
        />
      </Switch>
    );
  }
}

export default (props: { history: History }) => {
  const { history } = props;
  const page = useSelector<AppState, ActivePageState>(state => state.activePageState);
  return <App history={history} page={page} />;
}
