import React, { CSSProperties, ReactNode, useEffect } from 'react';
import { History } from 'history';
import { ModalProps } from '../modals/BaseModal';
import { Icon } from '../components/Icons';
import { ModalTarget } from '../modals/ModalTarget';
import { Loading, LoadingItem } from '../utils/Loading';
import { useDispatch } from 'react-redux';
import { AnyAppAction, setActivePage, setSharedPageState } from '../redux/Actions';
import { ISharedPageState } from '../redux/State';
import { useAppDispatch } from '../utils/Functional';
import { PageID } from '../utils/PageLinking';
import { Breadcrumb, useBreadcrumbs } from './PageBreadcrumb';

export enum PageLayout {
  Regular, // default
  Public, // public pages (public search, story pair)
  PublicPerson, // public person or person story (different title fonts)
  PublicPersonKey, // public person key story (additional logo)
  NoFrames // used by login
}

export interface BaseContextPageProps {
  history: History;
  modals: ModalTarget;
  loading: Loading;
}

export interface ContextPageProps extends BaseContextPageProps {
  pending: LoadingItem[];
}

export interface PageProps extends ContextPageProps {
  via?: string;
}

export interface PagePropsNoPending extends BaseContextPageProps {
  via?: string;
}

export interface PageState {
  page: BasePageInfo;
}

export interface PageInfo {
  page?: string;
  icon?: Icon;
  title: string;
  subtitle?: string;
  via?: string;
  hasBackButton?: boolean;
  breadcrumbs: Breadcrumb[],
  confirmNavigation?: () => Promise<boolean>;
}

interface BasePageInfo {
  id: PageID;
  entity?: string;
  page?: string;
  icon?: Icon;
  title: string;
  subtitle?: string;
  shortTitle?: string;
  via?: string;
  viaTitle?: string;
  hasBackButton?: boolean;
  layout?: PageLayout;
  confirmNavigation?: () => Promise<boolean>;
}

export function usePageHeader(
  id: PageID,
  entity: string|undefined,
  icon: Icon|undefined,
  title: string,
  shortTitle: string,
  via?: string,
  viaTitle?: string,
  confirmNavigation?: () => Promise<boolean>,
  subtitle?: string
) {
  const dispatch = useDispatch<(action: AnyAppAction) => void>();
  const breadcrumbs = useBreadcrumbs(shortTitle, via || 'none', viaTitle);
  useEffect(() => {
    dispatch(setActivePage({ id, entity, icon, title, subtitle, via, breadcrumbs, confirmNavigation }));
  }, [dispatch, icon, title, subtitle, breadcrumbs, confirmNavigation, entity, id]);
}

export interface PageComponentProps {
  id: PageID;
  entity?: string;
  icon: Icon;
  title: string;
  shortTitle?: string;
  via?: string;
  viaTitle?: string;
  containerStyle?: CSSProperties;
  confirmNavigation?: () => Promise<boolean>;
  children: ReactNode;
  sharedPageState?: ISharedPageState;
}

const defaultContainerStyle: CSSProperties = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column'
};

export const Page = (props: PageComponentProps) => {
  const {
    id,
    entity,
    icon,
    title,
    shortTitle = title,
    via,
    viaTitle,
    containerStyle = defaultContainerStyle,
    confirmNavigation,
    children,
    sharedPageState = {},
  } = props;


  usePageHeader(id, entity, icon, title, shortTitle, via, viaTitle, confirmNavigation);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setSharedPageState(sharedPageState));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
          // -- this behavior is intentional: we only intend to submit an update when the page is first loaded

  return (
    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, position: 'relative', overflow: 'auto' }}>
      <div className='container' style={containerStyle}>
        {children}
      </div>
    </div>
  );
}

export class BasePage<P extends PageProps, S extends PageState> extends React.Component<P, S> {
  containerStyle: CSSProperties;

  constructor(props: P) {
    super(props);

    this.containerStyle = {
      flexGrow: 1
    };
  }

  loading<T>(promise: Promise<T>, displayName: string): Promise<T> {
    const { loading } = this.props;
    return loading.loading(promise, displayName);
  }

  saving<T>(promise: Promise<T>, displayName: string): Promise<T> {
    const { loading } = this.props;
    return loading.saving(promise, displayName);
  }

  componentDidMount() {
    this.loadContent();
  }

  componentDidUpdate(oldProps: P, oldState: S) {
    if (this.shouldLoadContent(oldProps, this.props))
      this.loadContent();
  }

  shouldLoadContent(oldProps: P, newProps: P) {
    return false;
  }

  getTitle(): string {
    return this.state.page.title || '';
  }

  loadContent() {
    // Implemented by page
  }

  renderContent(): JSX.Element {
    // Implemented by page
    return <p>Please implement</p>;
  }

  showModal<T>(modal: (props: ModalProps<T>) => JSX.Element) {
    return this.props.modals.show(modal);
  }

  render() {
    const { page } = this.state;

    return (
      <Page
        id={page.id}
        entity={page.entity}
        icon={page.icon || Icon.Page}
        title={page.title}
        shortTitle={page.shortTitle}
        via={page.via}
        viaTitle={page.viaTitle}
        confirmNavigation={page.confirmNavigation}
        containerStyle={this.containerStyle}
      >
          {this.renderContent()}
      </Page>
    );
  }
}
