import React, { useState } from 'react';
import { Language } from "../../../models/PersonStory2";
import { T, TranslationKey } from "../../../Translate";
import { Badge, Dropdown } from "react-bootstrap";


export interface LanguageSelectorProps {
  onUpdateLanguage: (language: Language) => void
  errors?: Language[]  // array of the languages that should present an error indicator
}

export const LanguageSelector = (props: LanguageSelectorProps) => {
  /** Props */
  const { onUpdateLanguage, errors } = props;

  /** State **/
  const [currentLanguage, setCurrentLanguage] = useState(Language.NL);

  /** event handling **/

  const onLanguageChanged = (newLanguage: Language) => {
    onUpdateLanguage(newLanguage)
    setCurrentLanguage(newLanguage)
  }

  /** Helper methods **/

  const generateLanguageString = (language: Language): string => {
    switch (language) {
      case Language.NL: return T('language.nl')
      case Language.FR: return T('language.fr')
      case Language.EN: return T('language.en')
      case Language.DE: return T('language.de')
    }
  }

  const hasError = (language: Language): boolean => {
    return errors?.includes(language) ?? false
  }

  const hasErrors = (): boolean => {
    if (!errors)
      return false;

    return errors.length > 0
  }

  const CustomToggle = React.forwardRef<HTMLAnchorElement>(
    // @ts-ignore
    ({ children, onClick }, ref) => (
      <a // eslint-disable-line jsx-a11y/anchor-is-valid
        href=""
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        &#x25bc;
      </a>
  ));

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
          {hasErrors() &&
            <Badge style={{marginRight: 6}} pill variant={"danger"}>!</Badge>
          }
          {generateLanguageString(currentLanguage)}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {Object.values(Language).map(language => <Dropdown.Item key={language as Language} as="button" onSelect={(eventKey: any, event: Object) => onLanguageChanged(language as Language)}>
            {T(('language.' + language) as TranslationKey)}
            {hasError(language) &&
             <Badge style={{marginLeft: 6}} pill variant={"danger"}>!</Badge>
            }
          </Dropdown.Item>)}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
};
