import React, { useMemo, useState, useEffect } from 'react';
import { PageProps, Page } from '../Page';
import { Icon } from '../../components/Icons';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { TextInput } from '../../components/inputs/TextInput';
import {
  MemorialType,
  translateMemorialType,
  MemorialTypes,
  PlateDetails,
  CemeteryKind,
  translateCemeteryKind,
  translatePlateDetails,
  IMemorialFields, GetMemorialOption
} from '../../models/Memorial';
import { RadioInput } from '../../components/inputs/RadioInput';
import { SelectInput, ISelectOption } from '../../components/inputs/SelectInput';
import { TextareaInput } from '../../components/inputs/TextareaInput';
import { PlaceInput } from '../../components/inputs/PlaceInput';
import { MapMarkerInput } from '../../components/inputs/MapMarkerInput';
import { MapCoordinatesInput } from '../../components/inputs/MapCoordinatesInput';
import { FormCard } from '../../components/FormCard';
import { T } from '../../Translate';
import { IPlace, Place } from '../../models/Place';
import api from '../../api';
import { CheckboxInput } from '../../components/inputs/CheckboxInput';
import { showNotification } from '../../utils/Notification';
import { BottomButtonRow } from '../../components/ButtonRow';
import { useMemorial, useNumberOfRelatedDocuments } from '../../FunctionalAPI';
import { useStateWithError } from '../../utils/Functional';
import { useModalContext } from '../../modals/ModalContext';
import { NotificationManager } from 'react-notifications';
import { PageID, getPageVia } from '../../utils/PageLinking';
import { TopTab, TopTabs } from "../../components/TopTabs";
import ListDocumentsTab from "../ViewPlace/ListDocumentsTab";
import { actualPosition, actualPositionSource, actualPositionSourceText, isAddressPositioned } from "./LocationUtils";
import { BritishTrenchCoordinates } from '../../utils/BritishTrenchCoordinate';
import { ValidationError } from '../../models/ValidationResult';

interface EditMemorialProps extends PageProps {
  id?: string;
}

const LoadOptions = [GetMemorialOption.Place];

export default function EditMemorial(props: EditMemorialProps) {
  const { loading, id, history, via } = props;
  const modals = useModalContext();

  const memorialTypeOptions: JSX.Element[] = useMemo(() => MemorialTypes.map(type => (
    <option key={type} value={type}>{translateMemorialType(type)}</option>
  )), []);
  const cemeteryKindOptions: ISelectOption[] = useMemo(() => [CemeteryKind.Civilian, CemeteryKind.Military].map(kind => (
    { value: kind, title: translateCemeteryKind(kind) }
  )), []);
  const plateTypeOptions: ISelectOption[] = useMemo(() => [
    PlateDetails.Community,
    PlateDetails.Private,
    PlateDetails.Organisation,
    PlateDetails.Military
  ].map(type => (
    { value: type, title: translatePlateDetails(type) }
  )), []);

  const memorial = useMemorial(loading, id, LoadOptions);
  const title = id ? T('page.memorial.title.edit') : T('page.memorial.title.create');
  const icon = Icon.Monument;

  const [name, setName, nameError, setNameError] = useStateWithError('');
  const [otherNames, setOtherNames, otherNamesError, setOtherNamesError] = useStateWithError('');
  const [type, setType, typeError, setTypeError] = useStateWithError(MemorialType.Plate);
  const [plateKind, setPlateKind, plateKindError, setPlateKindError] = useStateWithError(PlateDetails.Community);
  const [cemeteryExists, setCemeteryExists] = useState(true);
  const [cemeteryKind, setCemeteryKind, cemeteryKindError, setCemeteryKindError] = useStateWithError(CemeteryKind.Civilian);
  const [place, setPlace, placeError, setPlaceError] = useStateWithError<IPlace|undefined>(undefined);
  const [description, setDescription, descriptionError, setDescriptionError] = useStateWithError('');
  const [address, setAddress, addressError, setAddressError] = useStateWithError('');
  // position when draging pin on google map
  const [customPosition, setCustomPosition] = useState<{ lat: number, lng: number }>();
  // position that matches the address
  const [addressPosition, setAddressPosition] = useState<{ lat: number, lng: number }>();
  const [addressLastSearched, setAddressLastSearched] = useState<string>('');
  const [britishTrenchCoordinates, setBritishTrenchCoordinates, britishTrenchCoordinatesError, setBritishTrenchCoordinatesError] = useStateWithError<string>('');
  const [coordinatesLocked, setCoordinatesLocked] = useState(false);

  const numberOfDocuments = useNumberOfRelatedDocuments('memorial', memorial?._id);


  const clearForm = () => {
    setName('');
    setOtherNames('');
    setType(MemorialType.Plate);
    setPlateKind(PlateDetails.Community);
    setCemeteryKind(CemeteryKind.Civilian);
    setCemeteryExists(true);
    setPlace(undefined);
    setDescription('');
    setAddress('');
    setCustomPosition(undefined);
    setAddressPosition(undefined);
    setAddressLastSearched('');
    setCoordinatesLocked(false);
  };

  useEffect(() => {
    const setLocations = () => {
      // locations set by dragging the pin or entering an address are passed as memorial.address_loc.
      setAddressPosition(undefined);
      setCustomPosition(undefined);

      // check if memorial does exist
      if (!memorial)
        return;

      // check if there are locations
      if (!memorial.address_loc)
        return;

      // check if location has valid values
      if (memorial.address_loc.lat === 0.0 || memorial.address_loc.lon === 0.0)
        return;

      if (memorial.address.length === 0
          && (memorial.address_loc.lon !== memorial.calc_place_loc.lon || memorial.address_loc.lat !== memorial.calc_place_loc.lat)
          && memorial.address_positioned === false)
        memorial.address_positioned = true;

      // if there is an address this location is not a custom location (address_position is true)
      // then use the address_positions
      if (memorial.address && memorial.address_positioned === false) {
        setAddressPosition({
          lat: memorial.address_loc.lat,
          lng: memorial.address_loc.lon
        });
        return;
      }

      // we have overruled the location with a custom position
      if (memorial.address_positioned) {
        setCustomPosition({
          lat: memorial.address_loc.lat,
          lng: memorial.address_loc.lon
        });
      }
    }

    if (memorial) {
      setName(memorial.name);
      setOtherNames(memorial.alternative_names.join(','));
      setType(memorial.type);
      setPlateKind(memorial.type_plate_details || PlateDetails.Community);
      setCemeteryKind((memorial.type_cemetery_details && memorial.type_cemetery_details.kind) || CemeteryKind.Civilian);
      setCemeteryExists(memorial.type_cemetery_details && memorial.type_cemetery_details.exists);
      setPlace(memorial.extend_place);
      setDescription(memorial.description);
      setAddress(memorial.address);
      setAddressLastSearched(memorial.address);
      setLocations();
      setCoordinatesLocked(memorial.position_locked);
      setBritishTrenchCoordinates(memorial.british_trench_coordinates || '');
    } else {
      clearForm();
    }
  }, [memorial]); // eslint-disable-line react-hooks/exhaustive-deps
  // dependencies verified - and not necessary here:
  // - setters never change
  // - clearForm only uses setters, too, thus all instances of clearForm are equivalent

  const geocodeAddress = () => {
    if (!address || address === addressLastSearched)
      return;

    if (!(address && address.length > 2)) {
      setAddressPosition(undefined);
      NotificationManager.error(T('memorial.position.notification.message.invalidAddress'))
      return;
    }

    if (!place) {
      NotificationManager.error(T('memorial.position.notification.message.needPlace'))
      return;
    }

    var geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address,
        componentRestrictions: {
          country: place.country_code,
          locality: new Place(place).getTranslation().name
        }
      },
      (results, status) => {
        setAddressLastSearched(address);

        if (status === google.maps.GeocoderStatus.OK) {
          const position = results[0].geometry.location;
          setAddressPosition({ lat: position.lat(), lng: position.lng() });
          setCustomPosition(undefined)
          NotificationManager.success(T('memorial.position.notification.message.addressFound'))
        }
        else {
          setAddressPosition(undefined);
          NotificationManager.success(T('memorial.position.notification.message.addressNotFound'))
        }
      });
  }

  const handleAddressEnterPressed = () => {
    if (!address) {
      setAddressPosition(undefined);
    } else {
      geocodeAddress();
    }
  }

  const handleMemorialTypeChanged = (value: string) => {
    setType(value as MemorialType);
  };

  const handleMemorialCemeteryTypeChanged = (value: string) => {
    setCemeteryKind(value as CemeteryKind);
  }

  const handlePlateKindChanged = (value: string) => {
    setPlateKind(value as PlateDetails);
  }

  const createNewMemorial = () => {
    if (id) {
      history.push('/memorials/create');
    } else {
      clearForm();
    }
  };

  const save = (createNew: boolean) => {
    const actualAddressPosition = addressPosition || { lat: 0, lng: 0 };
    const actualCustomPosition = customPosition || addressPosition || undefined;

    const positionSource = actualPositionSource(customPosition,addressPosition, place);
    const addressPositioned: boolean = isAddressPositioned(positionSource);

    const data: IMemorialFields & { _id?: string } = {
      _id: id,
      type,
      type_cemetery_details: {
        kind: cemeteryKind,
        exists: cemeteryExists,
        nationality: ''
      },
      type_plate_details: type === MemorialType.Plate ? plateKind: undefined,
      name,
      alternative_names: otherNames ? otherNames.split(',') : [],
      address,
      address_loc: actualCustomPosition ? { lat: actualCustomPosition.lat, lon: actualCustomPosition.lng } : { lat: 0, lon: 0 },
      position_locked: coordinatesLocked,
      description,
      start_day: null,
      start_month: null,
      start_year: null,
      start_date: null,
      calc_place_name: '', // not used at server side
      calc_place_loc: { lat: actualAddressPosition.lat, lon: actualAddressPosition.lng }, // not used at server side
      calc_place_country: place ? place.country_code : '', // not used as server side
      address_positioned: addressPositioned,
      place_id: place ? place._id : undefined
    };
    
    api.validateMemorial(data, id ? 'update' : 'create').then(result => {
      if (result.status === 'NOK') {
        const errors = result.data || {};
        setNameError(errors.name);
        setOtherNamesError(errors.alternative_names);
        setAddressError(errors.address);
        setDescriptionError(errors.description);
        setTypeError(errors.type);
        setPlateKindError(errors.type_plate_details);
        setCemeteryKindError(errors.cemetery_kind);
        setPlaceError(errors.place_id);
        setBritishTrenchCoordinatesError(errors.british_trench_coordinates);
        return;
      }

      if (id) {
        const dataWithId = Object.assign(data, { _id: id });
        api.updateMemorial(dataWithId)
          .then(() => {
            showNotification({ message: T('page.memorial.updated') });
            if (createNew)
              createNewMemorial();
          });
      } else {
        api.createMemorial(data)
          .then(memorial => {
            showNotification({ message: T('page.memorial.created') });
            if (createNew) {
              createNewMemorial();
            } else {
              history.push(`/memorials/${memorial._id}`);
            }
          });
      }
    });
  }

  const validateBritishTrenchCoordinates = () => {
    if (britishTrenchCoordinates.length === 0)
      return;
    
    const coordinates = BritishTrenchCoordinates.parse(britishTrenchCoordinates);
    if (coordinates) {
      setBritishTrenchCoordinates(coordinates.toString());
    } else {
      setBritishTrenchCoordinatesError(ValidationError.InvalidBritishTrenchCoordinates);
    }
  };

  const handleClickedSave = () => {
    save(false);
  };

  const leftForm = (
    <Form>
      <TextInput
        name='name'
        label={T('memorial.name')}
        value={name}
        error={nameError}
        placeholder={T('memorial.name')}
        onChange={setName}
      />
      <TextInput
        name='other-names'
        label={T('memorial.otherNames')}
        value={otherNames}
        error={otherNamesError}
        placeholder={T('memorial.otherNames')}
        onChange={setOtherNames}
      />
      <SelectInput
        name='type'
        label={T('memorial.type')}
        value={type}
        error={typeError}
        optionElements={memorialTypeOptions}
        onChange={handleMemorialTypeChanged}
      />
      {type === MemorialType.Cemetery && (
        <RadioInput
          value={cemeteryKind}
          options={cemeteryKindOptions}
          error={cemeteryKindError}
          onChange={handleMemorialCemeteryTypeChanged}
        />
      )}
      {type === MemorialType.Plate && (
        <RadioInput
          value={plateKind}
          error={plateKindError}
          options={plateTypeOptions}
          onChange={handlePlateKindChanged}
        />
      )}
      {(type === MemorialType.Cemetery || type === MemorialType.Plate) && (
        <CheckboxInput
          label={T('memorial.exists')}
          value={cemeteryExists}
          onChange={setCemeteryExists}
        />
      )}
      <TextareaInput
        name='description'
        label={T('memorial.description')}
        value={description}
        error={descriptionError}
        onChange={setDescription}
        placeholder={T('memorial.description')}
      />
    </Form>
  );

  const actualPositionOnMap = actualPosition(customPosition, addressPosition, place);
  const actualPositionOnMapSource = actualPositionSource(customPosition, addressPosition, place);
  const actualPositionOnMapSourceText = actualPositionSourceText(actualPositionOnMapSource)

  const rightForm = (
    <Form>
      <PlaceInput
        name='place'
        label={T('memorial.place')}
        value={place}
        error={placeError}
        placeholder={T('memorial.searchPlace')}
        onChange={setPlace}
        target={'window'}
      />
      <TextInput
        name='address'
        label={T('memorial.address')}
        value={address}
        error={addressError}
        placeholder={T('memorial.address')}
        info={T('page.memorial.addressEnterToLocate')}
        onChange={setAddress}
        onPressedEnter={handleAddressEnterPressed}
        onBlur={handleAddressEnterPressed}
      />
      <MapMarkerInput
        label={T('memorial.position')}
        position={actualPositionOnMap}
        onChange={setCustomPosition}
        info={actualPositionOnMapSourceText}
        readOnly={coordinatesLocked}
      />
      <CheckboxInput
        label={T('memorial.coordinates.locked')}
        value={coordinatesLocked}
        onChange={setCoordinatesLocked}
      />

      <MapCoordinatesInput
        name='coordinates'
        label={T('memorial.coordinates')}
        position={customPosition}
        onChange={setCustomPosition}
      />
      <TextInput
        name='british_trench_coordinates'
        label={T('memorial.britishTrenchCoordinates')}
        value={britishTrenchCoordinates}
        onChange={setBritishTrenchCoordinates}
        onBlur={validateBritishTrenchCoordinates}
        error={britishTrenchCoordinatesError}
      />
    </Form>
  );

  /*** rendering ***/
  const generateTabs = (): TopTab[] => {
    return [
      {
        icon: Icon.Monument,
        title: T('field.memorated.memorial.name'),
        id: 'persons',
        content: () => renderMemorialTab(),
        badgeContent: () => '',
      },
      {
        icon: Icon.Document,
        title: T('page.place.tab.documents'),
        id: 'documents',
        content: () => renderDocumentsTab(),
        badgeContent: () => numberOfDocuments.toString(),
      }
    ]
  }

  const renderMemorialTab = () => {
    return (
      <>
        <Row style={{alignItems: 'stretch', paddingTop: 15}}>
          <Col md={6}>
            <FormCard
              style={{height: '100%'}}
              icon={Icon.Info}
              title={T('page.memorial.panel.memorial')}
            >
              {leftForm}
            </FormCard>
          </Col>
          <Col md={6}>
            <FormCard
              style={{height: '100%'}}
              icon={Icon.MapMarker}
              title={T('page.memorial.panel.location')}
            >
              {rightForm}
            </FormCard>
          </Col>
        </Row>
        <BottomButtonRow>
          <Button variant='primary' onClick={handleClickedSave}>
            {T('generic.action.save')}
          </Button>
        </BottomButtonRow>
      </>
    )
  }

  const renderDocumentsTab = () => {
    if (!memorial) {
      return ''
    } else {
      return (
        <ListDocumentsTab
          ref_id={memorial._id}
          ref_name={memorial.name ?? ''}
          ref_type='memorial'
          history={history}
          loading={loading}
          modals={modals}
          via={getPageVia(PageID.EditMemorial, memorial._id)}
          readonly={false}
          pageStateKey='editMemorialDocumentation'
        />
      );
    }
  }

  return (
    <Page
      id={id ? PageID.EditMemorial : PageID.CreateMemorial}
      entity={id}
      icon={icon}
      title={title}
      shortTitle={T('generic.breadcrumb.edit')}
      via={via || getPageVia(PageID.ViewMemorial, id)}
      viaTitle={via ? undefined : (memorial ? memorial.name : '')}
    >
      <TopTabs
        id='view-memorials-tabs'
        defaultActiveKey='persons'
        storeKey='editMemorial'
        tabs={generateTabs()}/>

    </Page>
  );
}
