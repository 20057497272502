import React from 'react';
import { SideBar, SideBarColor } from '../theme/SideBar';
import { T } from '../../Translate';
import logo from '../../assets/images/icon.png';
import background from '../../assets/images/sidebar.jpg';
import { Nav, Button } from 'react-bootstrap';
import { NavItem } from '../theme/NavItem';
import { NavGroup, NavGroupItem } from '../theme/NavGroup';
import { Icon } from '../Icons';
import api from '../../api';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../redux/State';
import { ActiveContactMoment } from '../../models/ActiveContactMoment';
import styles from './AppSideBar.module.scss';
import { Link } from 'react-router-dom';
import { AnyAppAction, endContactMomentAction } from '../../redux/Actions';
import { Dispatch } from 'redux';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import { inputDateTimeToISO } from '../inputs/DateTimeInput';
import { ContactPersonEvent } from '../../models/ContactPerson';

const ContactMomentView = (props: { contactMoment: ActiveContactMoment }) => {
  const { contactMoment } = props;

  const dispatch = useDispatch<Dispatch<AnyAppAction>>();
  const handleClickedStop = () => {
    const startDateTime = inputDateTimeToISO(contactMoment.startsAt);
    if (!startDateTime) {
      NotificationManager.error(T('contactMoment.invalidStart'))
      return;
    }
    api.createContactPersonEvent({
      type: 'contact_moment',
      contactPersonId: contactMoment.person._id,
      contactMethod: contactMoment.type,
      start: startDateTime,
      end: moment().format('YYYY-MM-DDTHH:mm'),
      contributions: contactMoment.contributions,
      transcript: contactMoment.transcript
    } as ContactPersonEvent).then(() => {
      dispatch(endContactMomentAction());
      NotificationManager.success(T('contactMoment.stoppedSuccessfully'))
    });
  };

  return (
    <div className={styles.contactMoment}>
      <Link
        className={styles.contactMomentMain}
        to={`/record_contact_moment`}
      >
        <div>{T('contactMoment.navtitle')}</div>
        <div>{contactMoment.person.firstName + ' ' + contactMoment.person.lastName}</div>
      </Link>
      <div>
        <Button size="sm" title={T('contactMoment.action.stop')} onClick={handleClickedStop}>
          <i className={Icon.Stop} />
        </Button>
      </div>
    </div>
  );
}

interface AppSideBarProps {

}
export const AppSideBar = (props: AppSideBarProps) => {
  const user = api.user;
  const activeContactMoment = useSelector<AppState, ActiveContactMoment|undefined>
    (state => state.activeContactMoment.current);

  return (
    <SideBar
      title={T('app.title')}
      logo={logo}
      backgroundImage={background}
      color={SideBarColor.Red}
    >
      {activeContactMoment && <ContactMomentView contactMoment={activeContactMoment} />}
      {api.isLoggedIn() && (
        <Nav>
          <NavItem to='/persons/search' icon={Icon.Search} title={T('menu.persons.search')} />
          <NavItem to='/persons' icon={Icon.Users} title={T('menu.persons')} />
          <NavItem to='/places' icon={Icon.MapMarker} title={T('menu.places')} />
          <NavItem to='/military_entities' icon={Icon.Sitemap} title={T('menu.militaryEntities')} />
          <NavItem to='/memorials' icon={Icon.Monument} title={T('menu.memorials')} />
          <NavGroup id='menu-documengroup' icon={Icon.Documentation} title={T('menu.documentgroup')}>
            <NavGroupItem to='/collections' icon={Icon.Box} title={T('menu.collections')} />
            <NavGroupItem to='/documentation' icon={Icon.FilesMedical} title={T('menu.documentation.items')} />
          </NavGroup>
          {user.isAtLeastRevisor() && <NavItem to='/personstories' icon={Icon.PersonStory} title={T('menu.personstories')} />}
          {user.isAtLeastRevisor() &&
            <NavGroup id='menu-contact-persons' icon={Icon.AddressBook} title={T('menu.management.contactPersons')}>
              <NavGroupItem to='/contact_persons' icon={Icon.AddressBook} title={T('page.listContactPersons.title')}/>
              <NavGroupItem to='/contact_moments' icon={Icon.ContactMoment} title={T('page.listContactMoments.title')}/>
            </NavGroup>}
          {user.isAdmin() && (
            <NavGroup id='menu-management' icon={Icon.Cogs} title={T('menu.management')}>
              <NavGroupItem
                icon={Icon.Users}
                title={T('menu.management.users')}
                to='/users'
              />
              <NavGroupItem
                icon={Icon.Database}
                title={T('menu.management.advanced')}
                to='/functions'
              />
              <NavGroupItem
                icon={Icon.Books}
                title={T('menu.management.projects')}
                to='/projects'
              />
            </NavGroup>
          )}
        </Nav>
      )}
    </SideBar>
  );
}
