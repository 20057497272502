import React, { CSSProperties, ReactNode } from 'react';
import { Icon } from '../Icons';
import { classes } from '../../utils/Styles';
import styles from './Table.module.scss';
import { ButtonLink } from '../ButtonLink';
import PageLink from '../PageLink';
import { PageID } from '../../utils/PageLinking';

export enum ColumnAlign {
  Left,
  Right
}
export interface ICellProps {
  onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;
  className?: string;
  style?: CSSProperties;
}
export interface IColumnOptions {
  sortable: boolean;
  align: ColumnAlign;
  clickable: boolean;
  width?: number;
  maxWidth?: number;
  ellipsize: boolean;
  className?: string;
  filter?: IFilterOption[];
  icon?: string;
}

export interface IFilterOption {
  key: string,
  value: any,
  label: string
}

export const DefaultColumnOptions: IColumnOptions = {
  sortable: true,
  align: ColumnAlign.Left,
  clickable: true,
  ellipsize: false
}

export interface ITableColumn<T, S = {}> {
  id: string;
  title: string;
  options: IColumnOptions;

  render(item: T, props: ICellProps, state: S): JSX.Element;

  sort: (a: T, b: T) => number;
}

export class StringTableColumn<T> implements ITableColumn<T> {
  id: string;
  title: string;
  getter: (item: T) => string|undefined;
  options: IColumnOptions;

  constructor(
    id: string,
    title: string,
    getter: (item: T) => string|undefined,
    options?: Partial<IColumnOptions>
  ) {
    this.id = id;
    this.title = title;
    this.getter = getter;
    this.options = Object.assign({}, DefaultColumnOptions, options);
  }

  render(item: T, props: ICellProps): JSX.Element {
    const value = this.getter(item);
    if (value === undefined)
      props.className = classes(styles.noValue, props.className);
    
    return <td key={this.id} {...props}>{value === undefined ? 'N/A' : value}</td>;
  }

  sort = (a: T, b: T): number => {
    return (this.getter(a) || '').localeCompare(this.getter(b) || '');
  }
}

export class StringFieldTableColumn<T> implements ITableColumn<T> {
  id: string & keyof T;
  title: string;
  options: IColumnOptions;

  constructor(
    id: string & keyof T,
    title: string,
    options?: Partial<IColumnOptions>
  ) {
    this.id = id;
    this.title = title;
    this.options = Object.assign({}, DefaultColumnOptions, options);
  }

  render(item: T, props: ICellProps): JSX.Element {
    return <td key={this.id} {...props}>{item[this.id]}</td>;
  }

  sort = (a: T, b: T): number => {
    return (a[this.id] as any).toString().localeCompare(b[this.id] as any).toString();
  }
}

export class ComponentTableColumn<T> implements ITableColumn<T> {
  id: string;
  title: string;
  renderer: (item: T) => ReactNode;
  options: IColumnOptions;
  sort: (a: T, b: T) => number;

  constructor(
    id: string,
    title: string,
    renderer: (item: T) => ReactNode,
    options?: Partial<IColumnOptions>,
    sorter?: (a: T, b: T) => number
  ) {
    this.id = id;
    this.title = title;
    this.renderer = renderer;
    this.sort = sorter || ((a, b) => 0);
    this.options = Object.assign({}, DefaultColumnOptions, { sortable: false, clickable: false }, options);
  }

  render(item: T, props: ICellProps): JSX.Element {
    return <td key={this.id} {...props}>{this.renderer(item)}</td>;
  }
}

export enum TableRowActionStyle {
  Info = 'btn btn-info btn-link btn-xs',
  Success = 'btn btn-success btn-link btn-xs',
  Danger = 'btn btn-danger btn-link btn-xs',
  Warning = 'btn btn-warning btn-link btn-xs'
}
export interface ITableRowAction<T, S = {}> {
  icon: Icon|((state: S) => JSX.Element);
  tooltip?: string;
  style?: TableRowActionStyle;
  onClick?: (item: T, state: S) => void;
  page?: PageID;
  pageParam?: string;
}
export function tableRowActions<T, S = {}>(...actions: (false|null|undefined|ITableRowAction<T, S>)[]): ITableRowAction<T, S>[] {
  return actions.filter(action => action !== false && action !== null && action !== undefined) as ITableRowAction<T, S>[];
}

function isIcon<S>(icon: Icon|((state: S) => JSX.Element)): icon is Icon {
  return typeof(icon) === 'string';
}
export class ActionsTableColumn<T, S = {}> implements ITableColumn<T, S> {
  id: string;
  title: string;
  options: IColumnOptions;
  items: (item: T) => ITableRowAction<T, S>[];

  constructor(
    id: string,
    title: string,
    items: (item: T) => ITableRowAction<T, S>[],
    options?: Partial<IColumnOptions>
  ) {
    this.id = id;
    this.title = title;
    this.options = Object.assign({}, DefaultColumnOptions, { sortable: false, align: ColumnAlign.Right }, options);
    this.items = items;
  }

  render(item: T, props: ICellProps, state: S): JSX.Element {
    const actions = this.items(item).map((action, index) => {
      const { icon, tooltip, style = TableRowActionStyle.Success, onClick, page, pageParam } = action;
      return page ? (
        <PageLink key={index} className={style} page={page} param={pageParam} title={tooltip}>
          {isIcon(icon) ? <i className={icon} /> : icon(state)}
        </PageLink>
      ) : (
        <ButtonLink key={index} className={style} onClick={onClick && (() => onClick(item, state))} title={tooltip}>
          {isIcon(icon) ? <i className={icon} /> : icon(state)}
        </ButtonLink>
      );
    });
    const style = Object.assign({ cursor: 'default' }, props.style);
    return (
      <td key={this.id} className='td-actions text-right' style={style}>
        {actions}
      </td>
    );
  }

  sort = (a: T, b: T) => 0;
}
